import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import moment from "moment";

import styled from "styled-components/macro";
import {
  Dialog, Classes, Button,
} from "@blueprintjs/core";
import { 
  CardTagDuedate, CardTagMembers, CardTagLabels, CardTagLastActivity, CardTagComments, CardTagCheckitems, CardCheckitems
} from "./CardDetailComponents";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;
const SectionTitle = styled.h4`
`;
const Section = styled.div`
  padding-left: 5px;
`;
const CardTitle = styled.div`
  margin-bottom: 10px;
`;
const CardDesc = styled.p`
  font-size: 8px;
  color: grey;
  word-break: break-all;
`;
const CardTagsDiv = styled.div`
  margin-top: 20px;
`;
const ActionRow = styled.div`
  margin-bottom: 5px;
`;
const ActionText = styled.div`
  word-break: break-all;
`;
const ActionDate = styled.div`
  font-size: 8px;
  color: grey;
`;
const ActionByMember = styled.span`
  color: cadetblue;
`;


interface CardDetailPopupProps {
  cardId: string;
  isOpen: boolean;
  onClose: () => void;  
}

interface InjectedProps extends CardDetailPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class CardDetailPopup extends Component<CardDetailPopupProps> {
  @observable sortByDateASC = true;
  @action setSortByDateASC(value: boolean) { this.sortByDateASC = value; }

  constructor(props: CardDetailPopupProps) {
    super(props);
    makeObservable(this);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  handleSortByDateClicked = (e: any) => {
    this.setSortByDateASC(!this.sortByDateASC);
  }

  renderTrelloShortcut = () => {
    const card = this.trelloStore.cards.find(x => x.card_id === this.props.cardId);
    if(card && card.short_link) {
      return (
        <Button 
          minimal
          fill
          icon="link"
          text={i18n.t("popup-card-btn-gototrello")}
          onClick={() => {
            window.open('https://trello.com/c/'+card.short_link, '_blank');
          }}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const card = this.trelloStore.cards.find(x => x.card_id === this.props.cardId);
    return (
      <Dialog
        title={i18n.t("popup-card-title")}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        style={{}}
      >
        <Wrapper className={Classes.DIALOG_BODY}>
          <div>{this.renderTrelloShortcut()}</div>
          <SectionTitle>{i18n.t("popup-card-section-general")}</SectionTitle>
          <Section>
            {card && card.name && (
              <CardTitle>{card.name}</CardTitle>
            )}
            {card && card.desc && (
              <CardDesc>{card.desc}</CardDesc>
            )}
            {card && (
              <CardTagsDiv>
                <CardTagLastActivity card={card} showTitle={true} appStore={this.injected.appStore} />
                <CardTagDuedate card={card} showTitle={true} appStore={this.injected.appStore} />
                <CardTagMembers card={card} showTitle={true} appStore={this.injected.appStore} />
                <CardTagLabels card={card} showTitle={true} appStore={this.injected.appStore} />
                <CardTagComments card={card} showTitle={true} appStore={this.injected.appStore} />
                <CardTagCheckitems card={card} showTitle={true} appStore={this.injected.appStore} />
              </CardTagsDiv>
            )}
          </Section>
          <SectionTitle>{i18n.t("popup-card-section-checklists")}</SectionTitle>
          <Section>
            {card && (
              <CardCheckitems card={card} appStore={this.injected.appStore} />
            )}
          </Section>
          <SectionTitle>{i18n.t("popup-card-section-actions")}
            <Button 
              minimal
              text={this.sortByDateASC ? (i18n.t("popup-card-btn-sort-by-date") + " ▲") : (i18n.t("popup-card-btn-sort-by-date") + " ▼")}
              onClick={this.handleSortByDateClicked}
            />
          </SectionTitle>          
          <Section>
            { this.trelloStore.actionTableData.slice().sort((a, b) => {
              if (this.sortByDateASC) {
                if (a.date > b.date) { return 1; }
                if (a.date < b.date) { return -1; }
                return 0;
              } else {
                if (a.date > b.date) { return -1; }
                if (a.date < b.date) { return 1; }
                return 0;
              }                
            }).map((x, i) => {
              if(x.card_id === this.props.cardId) {
                return (
                  <ActionRow key={x.action_id}>
                    <ActionText><ActionByMember>{x.action_by}</ActionByMember> {x.desc}</ActionText>
                    <ActionDate>{moment(x.date).format("YYYY-MM-DD HH:mm:ss")}</ActionDate>
                  </ActionRow>
                );
              } else {
                return null;
              }
            })}
          </Section>
        </Wrapper>
      </Dialog>
    )
  }
}

export default withTranslation()(CardDetailPopup);