import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {
  IPanelProps, HTMLTable,
} from "@blueprintjs/core";
import CardsStack from "./CardsStack";

import { AppStore } from "../../../../stores/AppStore";


const Wrapper = styled.div``;

interface ListStackProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ListStack extends Component<ListStackProps & IPanelProps> {
  @observable readyToShow: boolean = false;
  @action setReadyToShow(value: boolean) { this.readyToShow = value; }

  constructor(props: ListStackProps & IPanelProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.setReadyToShow(true);
  }  

  get userStore() { return this.props.appStore.userStore; }
  get trelloStore() { return this.props.appStore.trelloStore; }

  // FPTree의 노드 id 를 받아서 리스트 이름으로 이루어진 패턴 문자열을 출력
  renderPattern = (node_id: string) => {
    return node_id.split(this.trelloStore.CONST_WF_PATTERN_NODE_DELIMETER).slice(1).map(list_id => {
      const list = this.trelloStore.lists.find(x => x.list_id === list_id);      
      return list ? list.name : null;
    }).join(" > ");
  }      

  // 패턴에 해당하는 카드 패널 화면에 올리기
  handleRowClicked = (nodeId: string, node: any) => {
    this.props.openPanel({
      component: CardsStack,
      props: {
        appStore: this.props.appStore,
        nodeId: nodeId,
        cardIds: node.cards,
      },
      title: "CARDS",
    })
  }  

  render() {
    const data = JSON.parse(this.trelloStore.workflowPatternsData);
    const list_data = data.list_data;
    return (
      <Wrapper>
        {this.readyToShow && (
          <HTMLTable 
            striped
            interactive
            style={{width:'100%'}}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Pattern</th>
                <th>Length</th>
                <th>Cards</th>
              </tr>
            </thead>
            <tbody>
              {list_data.map((row: any, idx: number) => {
                return (
                  <tr 
                    key={row.id}
                    onClick={() => {this.handleRowClicked(row.id, row)}}
                  >
                    <td style={{textAlign:'center'}}>{idx+1}</td>
                    <td>{this.renderPattern(row.id)}</td>
                    <td style={{textAlign:'center'}}>{row.id.split(this.trelloStore.CONST_WF_PATTERN_NODE_DELIMETER).length-1}</td>
                    <td style={{textAlign:'center'}}>{row.cards.length}</td>
                  </tr>
                );
              })}
            </tbody>
          </HTMLTable>
        )}
      </Wrapper>
    );
  }
}

export default ListStack;