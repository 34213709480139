export const CFD_LIST_COLOR = new Map<string, string>()
CFD_LIST_COLOR.set("none",    "#ffffff");
CFD_LIST_COLOR.set("info",    "#e4fbff");
CFD_LIST_COLOR.set("todo",    "#dddddd");
CFD_LIST_COLOR.set("doing",   "#f7d9d9");
CFD_LIST_COLOR.set("done",    "#f25287");
CFD_LIST_COLOR.set("deleted", "#822659");

export function CFD_LIST_TYPE_BY_COLOR(color:string) {
  let result = "";
  CFD_LIST_COLOR.forEach((value:string, key:string) => {
    if(value === color) {
      result = key;
    }
  });
  return result;
}