import { Component } from "react";

import { inject, observer } from "mobx-react";

import { RouteComponentProps, withRouter } from "react-router";

import styled from "styled-components/macro";
import { 
  Tab, Tabs,
 } from "@blueprintjs/core";

import ActionsTableTab from "../tabs/ActionsTableTab";
import ActionsTrendsTab from "../tabs/ActionsTrendsTab";
import ActionsMemberTab from "../tabs/ActionsMemberTab";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;


interface PageParams {
  boardId: string;
  tabId: string;
}
interface ActionsPanelProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends ActionsPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ActionsPanel extends Component<ActionsPanelProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    const { boardId, tabId } = this.props.match.params;
    return (
      <Wrapper>
        <Tabs
          id="tabs-actions"
          onChange={(newTabId) => {this.props.history.push(`/trello/${boardId}/actions/${newTabId}`)}}
          selectedTabId={tabId ? tabId : 'table'}
        >
          <Tab id="table" title={i18n.t("actions-tabtitle-table")} panel={(<ActionsTableTab />)} />
          <Tab id="trends" title={i18n.t("actions-tabtitle-trends")} panel={(<ActionsTrendsTab />)} />
          <Tab id="member" title={i18n.t("actions-tabtitle-members")} panel={(<ActionsMemberTab />)} />
        </Tabs>         
      </Wrapper>
    )
  }
}

export default withTranslation()(withRouter(ActionsPanel));