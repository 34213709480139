import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {
  IPanelProps, Breadcrumbs, IBreadcrumbProps,
} from "@blueprintjs/core";
import SelectdCardList from "../SelectedCardList";

import { AppStore } from "../../../../stores/AppStore";


const Wrapper = styled.div``;

interface CardsStackProps {
  appStore: AppStore;
  nodeId: string;
  cardIds: Array<string>;
}


@inject("appStore")
@observer
class CardsStack extends Component<CardsStackProps & IPanelProps> {
  @observable readyToShow: boolean = false;
  @action setReadyToShow(value: boolean) { this.readyToShow = value; }

  constructor(props: CardsStackProps & IPanelProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.setReadyToShow(true);
  }  

  get userStore() { return this.props.appStore.userStore; }
  get trelloStore() { return this.props.appStore.trelloStore; }

  nodeIdToBreadcrumbs = () => {
    return this.props.nodeId.split(this.trelloStore.CONST_WF_PATTERN_NODE_DELIMETER).slice(1).map(list_id => {
      const list = this.trelloStore.lists.find(x => x.list_id === list_id);      
      return {text: list ? list.name : null};
    });
  }

  render() {
    return (
      <Wrapper>
        {this.readyToShow && (
          <div>
            <div style={{padding: '5px', backgroundColor: 'lightgray', marginBottom: '5px'}}>
              <Breadcrumbs
                items={this.nodeIdToBreadcrumbs()}
                breadcrumbRenderer={(props: IBreadcrumbProps) => {
                  return <span>{props.text}</span>
                }}
              />
            </div>
            <SelectdCardList 
              isSelected={(card) => {
                return this.props.cardIds.includes(card.card_id);
              }}
            />
          </div>
        )}
      </Wrapper>
    );
  }
}

export default CardsStack;