import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {
  Button,
} from "@blueprintjs/core";

import { withTranslation } from "react-i18next";
import i18n from "../../utils/i18n/i18n";

import { AppStore } from "../../stores/AppStore";

import "./GuestPanel.css";

const Wrapper = styled.div`
  font-size: 16px;
  line-height: normal;
  box-sizing: border-box;
`;
const Top = styled.div`
  width: 100%;
  padding: 100px 40px;
  text-align: center;
  position: relative;
  background: url("/images/time_machine.jpg") no-repeat;
  background-size: cover;
  background-position: center;
`;

interface GuestPanelProps {}

interface InjectedProps extends GuestPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class GuestPanel extends Component<GuestPanelProps> {
  @observable slide = 0;
  @action setSlide(value: number) { this.slide = value; }

  @observable timerId: number = 0;
  @action setTimerId(value: any) { this.timerId = value; }

  constructor(props: GuestPanelProps) {
    super(props);
    makeObservable(this);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  componentDidMount() {
    this.setTimerId(setInterval(() => {
      this.setSlide((this.slide + 1) % 3);
    }, 3000));
  }
  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  handleLogin = () => {
    const return_url = `${window.location.protocol}//${window.location.host}/loginproc/`;
    const app_name = "Time Machine";
    const scope = "read,account";
    const expiration = "never";
    const callback_method = "fragment";
    const response_type = "fragment";
    window.open(`https://api.trello.com/1/authorize/?name=${app_name}&scope=${scope}&expiration=${expiration}&return_url=${return_url}&callback_method=${callback_method}&response_type=${response_type}&key=${this.trelloStore.CONST_TRELLO_API_KEY}`, '_self')
  }

  render() {
    return (
      <Wrapper>
        <Top>
          <h1 className="title">Time Machine</h1>
          <h2 className="subtitle">{i18n.t("landing-maintitle-sub")}</h2>
          <button type="button" className="loginButton" onClick={() => {this.handleLogin()}}><i className="fab fa-trello"></i> Login with Trello</button>
          <div className="lang">
            <Button
              minimal
              text="한국어"
              style={{color:'gray'}}
              onClick={() => {
                this.userStore.setLanguage("kr"); 
                i18n.changeLanguage("kr"); 
              }}
            />
            <Button
              minimal
              text="English"
              style={{color:'gray'}}
              onClick={() => {
                this.userStore.setLanguage("en"); 
                i18n.changeLanguage("en"); 
              }}
            />
          </div>
        </Top>
        <div className="features section">
          <div className="sectionTitle">{i18n.t("landing-sectiontitle-feature")}</div>
          <input type="radio" name="slider" id="item-1" onChange={() => { this.setSlide(0); }} checked={this.slide === 0} />
          <input type="radio" name="slider" id="item-2" onChange={() => { this.setSlide(1); }} checked={this.slide === 1} />
          <input type="radio" name="slider" id="item-3" onChange={() => { this.setSlide(2); }} checked={this.slide === 2} />          
          <div className="featureImagesContainer cards">
            <label htmlFor="item-1" id="card-1" className="featureImage card">
              <img src="/images/workflow.png" alt="" />
            </label>
            <label htmlFor="item-2" id="card-2" className="featureImage card">
              <img src="/images/highlight.png" alt="" />
            </label>
            <label htmlFor="item-3" id="card-3" className="featureImage card">
              <img src="/images/stack.png" alt="" />
            </label>
          </div>
          <div className="featureCellContainer">
            <div className="featureCell">
              <div className="featureIcon">
                <i className="fas fa-highlighter"></i>
              </div>
              <div className="featureName">{i18n.t("landing-subsectiontitle-highlights")}</div>
              <div className="featureDesc">{i18n.t("landing-subsectioncontent-highlights")}</div>
            </div>
            <div className="featureCell">
              <div className="featureIcon">
                <i className="fas fa-chart-pie"></i>
              </div>
              <div className="featureName">{i18n.t("landing-subsectiontitle-actions")}</div>
              <div className="featureDesc">{i18n.t("landing-subsectioncontent-actions")}</div>
            </div>
            <div className="featureCell">
              <div className="featureIcon">
                <i className="fas fa-backward"></i>
              </div>
              <div className="featureName">{i18n.t("landing-subsectiontitle-board")}</div>
              <div className="featureDesc">{i18n.t("landing-subsectioncontent-board")}</div>
            </div>
          </div>
          <div className="featureCellContainer">
            <div className="featureCell">
              <div className="featureIcon">
                <i className="fas fa-chart-area"></i>
              </div>
              <div className="featureName">{i18n.t("landing-subsectiontitle-progress")}</div>
              <div className="featureDesc">{i18n.t("landing-subsectioncontent-progress")}</div>
            </div>
            <div className="featureCell">
              <div className="featureIcon">
                <i className="fas fa-route"></i>
              </div>
              <div className="featureName">{i18n.t("landing-subsectiontitle-workflow")}</div>
              <div className="featureDesc">{i18n.t("landing-subsectioncontent-workflow")}</div>
            </div>
            <div className="featureCell">
              <div className="featureIcon">
                <i className="fas fa-filter"></i>
              </div>
              <div className="featureName">{i18n.t("landing-subsectiontitle-reports")}</div>
              <div className="featureDesc">{i18n.t("landing-subsectioncontent-reports")}</div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="pricing">
            <div className="sectionTitle">{i18n.t("landing-sectiontitle-pricing")}</div>
            <div className="priceCell">
              <div>  
                <div className="price">$0</div>
                <div className="priceLabel">While in beta</div>
              </div>
            </div>
            <div className="priceDesc">
            {i18n.t("landing-sectioncontent-pricing")}
            </div>
          </div>
        </div>
        <div className="examples section">
          <div className="sectionTitle">{i18n.t("landing-sectiontitle-examples")}</div>
          <div className="examplesDesc">{i18n.t("landing-sectioncontent-examples")}</div>
          <div className="examplesButtons">
            <a href="https://docs.google.com/presentation/d/1TnyUn5sCRspd99lsix2v1gY8QapdR0iPSVW1kDQN1rM/edit?usp=sharing" target="_blank" rel="noreferrer">P Project (Planning)</a>
            &nbsp;
            <a href="https://docs.google.com/presentation/d/1kHo6KzcdThC5-8kPBCcifW_-wdXCvKviAjHXY2VSgBE/edit?usp=sharing" target="_blank" rel="noreferrer">C Project (Design)</a>
            &nbsp;
            <a href="https://docs.google.com/presentation/d/1hUK6Zwo5T1audaiVgYXeEVUjYOlIBPpJhHJ-ddLXFtI/edit?usp=sharing" target="_blank" rel="noreferrer">S Project (Development)</a>
          </div>
        </div>
        <div className="getStarted">
          <div className="sectionTitle">{i18n.t("landing-sectiontitle-gettingstarted")}</div>
          <button type="button" className="loginButton" onClick={() => {this.handleLogin()}}><i className="fab fa-trello"></i> Login with Trello</button>
        </div>
        <footer className="footer">
          <div className="footerInner">
            <div>Copyright 2021. Seeso Inc. All rights reserved.</div>
            <div className="links">      
              <a href="/terms-of-services/">Terms of Services</a>
              <a href="/privacy-policy/">Privacy Policy</a>
            </div>
          </div>
        </footer>
      </Wrapper>
    )
  }
}

export default withTranslation()(GuestPanel);