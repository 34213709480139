import { Component } from "react";

import styled from "styled-components/macro";


const Wrapper = styled.div``;

interface TrelloPowerUpPageProps {}

class TrelloPowerUpPage extends Component<TrelloPowerUpPageProps> {
  componentDidMount () {
    const script_trello = document.createElement("script");
    script_trello.src = "https://p.trellocdn.com/power-up.min.js";
    document.body.appendChild(script_trello);

    const script_client = document.createElement("script");
    script_client.src = "/powerup/client.js";
    document.body.appendChild(script_client);
  }

  render() {
    return (
      <Wrapper>
      </Wrapper>
    )
  }
}

export default TrelloPowerUpPage;