import React, { Component } from "react";
import styled from "styled-components/macro";
import { inject, observer } from "mobx-react";
import { AppStore } from "../stores/AppStore";
import { observable, computed, action, makeObservable } from "mobx";
import { Spinner } from "@blueprintjs/core";
import { withRouter, RouteComponentProps } from "react-router";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface CheckLoginProps extends RouteComponentProps {}
interface InjectedProps extends CheckLoginProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class CheckLogin extends Component<CheckLoginProps> {
  @observable readyToShow = false;

  @action setReadyToShow(value: boolean) { this.readyToShow = value; }

  constructor(props: CheckLoginProps) {
    super(props);
    makeObservable(this);
  }

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get isLoggedIn() {
    return this.injected.appStore.userStore.isLoggedIn;
  }

  @computed
  get userStore() {
    return this.injected.appStore.userStore;
  }

  async componentDidMount() {
    const authToken = this.userStore.authToken;

    window.localStorage.setItem('nexturl', this.props.location.pathname);
    if (authToken) {
      this.userStore.checkLoginStatus();
    }
    this.setReadyToShow(true);
  }

  render() {
    if (!this.readyToShow) {
      return (
        <Wrapper>
          <Spinner />
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

export default withRouter(CheckLogin);
