import { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { 
 } from "@blueprintjs/core";
import { 
  HorizontalBar
} from "react-chartjs-2";

import { withTranslation } from "react-i18next";
import i18n from "../../../../utils/i18n/i18n";

import { AppStore } from "../../../../stores/AppStore";


const Wrapper = styled.div``;
const ChartDiv = styled.div`
  min-width: 800px;
  border: 1px solid lightgray;
`;

interface PerformanceCardStatusProps {}

interface InjectedProps extends PerformanceCardStatusProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PerformanceCardStatus extends Component<PerformanceCardStatusProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    const data = JSON.parse(this.trelloStore.highlightsData);

    return (
      <Wrapper>
        <ChartDiv>
          <HorizontalBar 
            width={4}
            height={1}
            data={{
              labels: [i18n.t("highlights-chart-current"), i18n.t("highlights-chart-previous")],
              datasets: data.card_status.datasets,
            }}
            options={{
              title: {
                display: true,
                text: i18n.t("highlights-chart-cardstatus")
              },
              scales: {
                xAxes: [{
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                  },
                }],
                yAxes: [{
                  stacked: true,
                }],
              },
              legend: { 
                position: 'top', 
              },
            }}
          />
        </ChartDiv>
      </Wrapper>
    )
  }
}

export default withTranslation()(PerformanceCardStatus);