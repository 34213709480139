import { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {  } from "@blueprintjs/core";
import { Pie, Line } from "react-chartjs-2";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;
const ParticipantChartDiv = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid lightgray;
  box-shadow: 5px 2px 2px lightgray;
  padding: 5px;
`;
const ActionMemberChartDiv = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  border: 1px solid lightgray;
  box-shadow: 5px 2px 2px lightgray;
  padding: 5px;
`;

interface ActionsMemberTabProps {}

interface InjectedProps extends ActionsMemberTabProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ActionsMemberTab extends Component<ActionsMemberTabProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  sum = (a: number, b: number) => { return a + b; }

  render() {
    const actionby_names = this.trelloStore.selectableActionBys.map(x => `${x.fullname} (${x.username})`);
    const actionby_actions = this.trelloStore.selectableActionBys.map(x => x.num_actions);
    const actionby_total = actionby_actions.reduce(this.sum, 0);
    const actionby_colors = this.trelloStore.selectableActionBys.map(x => x.chart_color);

    const member_names = this.trelloStore.selectableMembers.map(x => x.member_id === 'none' ? 'none' : `${x.fullname} (${x.username})`);
    const member_cards = this.trelloStore.selectableMembers.map(x => x.num_cards);
    const member_cards_total = member_cards.reduce(this.sum, 0);
    const member_actions = this.trelloStore.selectableMembers.map(x => x.num_actions);
    const member_actions_total = member_actions.reduce(this.sum, 0);
    const member_colors = this.trelloStore.selectableMembers.map(x => x.chart_color);

    const action_member_data = JSON.parse(this.trelloStore.actionMemberData);

    return (
      <Wrapper>
        <ParticipantChartDiv>
          <div style={{width:'50%'}}>
          <Pie 
            data={{
              labels: actionby_names,
              datasets: [{
                data: actionby_actions.map(x => Math.round((x/actionby_total)*1000)/10),
                backgroundColor: actionby_colors,
              }]
            }}
            options={{
              legend: { position: 'left' },
              title: {
                display: true,
                text: i18n.t("actions-member-chart-shareofactions")
              },
            }}
          />
          </div>
          <div style={{width:'1px', backgroundColor: 'lightgray'}} />
          <div style={{width:'50%'}}>
          <Pie 
            data={{
              labels: member_names,
              datasets: [{
                label: '# of cards',
                data: member_cards.map(x => Math.round((x/member_cards_total)*1000)/10),
                backgroundColor: member_colors,
              }, {
                label: '# of actions',
                data: member_actions.map(x => Math.round((x/member_actions_total)*1000)/10),
                backgroundColor: member_colors,
              }]
            }}
            options={{
              legend: { position: 'left' },
              title: {
                display: true,
                text: i18n.t("actions-member-chart-shareofcards")
              }
            }}
          />
          </div>          
        </ParticipantChartDiv>
        
        <ActionMemberChartDiv>
          <div style={{width:'50%'}}>
            <Line 
              data={{
                labels: action_member_data.labels_date ? action_member_data.labels_date : [],
                datasets: action_member_data.datasets_date ? action_member_data.datasets_date : []
              }}
              options={{
                scales: {
                  xAxes: [{
                    type: "time",
                    time: {
                      unit: 'day',
                      unitStepSize: 7,
                      tooltipFormat: "YYYY-MM-DD",
                      displayFormats: {
                        day: 'YYYY-MM-DD'
                      }
                    },
                    gridLines: {
                      color: 'black',
                      borderDash: [20, 2],
                    }
                  }],
                  yAxes: [{
                    ticks: {},
                  }],
                },
                legend: { position: 'left', align: 'start' },
                title: {
                  display: true,
                  text: i18n.t("actions-member-chart-actionsbydates")
                }
              }}
            />
          </div>
          <div style={{width:'1px', backgroundColor: 'lightgray'}} />
          <div style={{width:'50%'}}>
            <Line 
              data={{
                labels: action_member_data.labels_hour ? action_member_data.labels_hour : [],
                datasets: action_member_data.datasets_hour ? action_member_data.datasets_hour : []
              }}
              options={{
                scales: {
                  xAxes: [{}],
                  yAxes: [{}],
                },
                legend: { position: 'left', align: 'start' },
                title: {
                  display: true,
                  text: i18n.t("actions-member-chart-workinghours")
                }
              }}
            />
          </div>
        </ActionMemberChartDiv>
      </Wrapper>
    )
  }
}

export default withTranslation()(ActionsMemberTab);