import { Component } from "react";

import { observable, makeObservable, action } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { 
  Tooltip, Button, Intent,
} from "@blueprintjs/core";
import { DateInput, Classes, } from "@blueprintjs/datetime";

import moment from "moment";

import { AppStore } from "../../../stores/AppStore";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";


const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const MainTitle = styled.h3`
  width: 100px;
  margin: 20px 0;
  margin-right: 10px;
`;


interface TargetDateInputProps {}

interface InjectedProps extends TargetDateInputProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TargetDateInput extends Component<TargetDateInputProps> {
  @observable isDrawerOpen = false;
  @action setIsDrawerOpen(value: boolean) { this.isDrawerOpen = value; }

  constructor(prop: TargetDateInputProps) {
    super(prop);
    makeObservable(this);
  }

  get injected() {
    return this.props as InjectedProps;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  get trelloStore() {
    return this.injected.appStore.trelloStore;
  }

  renderDay = (day: Date) => {
    const date = day.getDate();
    const has_data = this.trelloStore.selectableDates.find(x => moment(x).isSame(moment(day), 'days'))
    return (
      <div className={Classes.DATEPICKER_DAY_WRAPPER}>
        {has_data ? (<span style={{color:'red'}}>{date}</span>) : date}
      </div>
    );
  }

  render() {
    return (
      <Wrapper>
        <Tooltip placement="right" content={<span>{i18n.t("filter-targetdate-tooltip")}</span>}>
          <MainTitle>{i18n.t("filter-targetdate")}</MainTitle>
        </Tooltip>
        <DateInput
          parseDate={(str) => new Date(str)}
          formatDate={(date: Date) => moment(date).format("YYYY-MM-DD")}
          placeholder="Target Date"
          minDate={new Date(this.trelloStore.startDate)}
          maxDate={new Date(this.trelloStore.maxDate)}
          closeOnSelection={true}
          value={new Date(this.trelloStore.targetDate)}
          onChange={(e) => { this.trelloStore.setTargetDate(e); }}
          dayPickerProps={{renderDay: this.renderDay}}
        />
        <Button 
          text={i18n.t("filter-apply")}
          intent={Intent.PRIMARY}
          onClick={async () => {
            try {
              this.trelloStore.setIsAnalyzed(false);
              await this.trelloStore.analyzeTrelloData(); 
            } catch(e) {
              alert(`${e.name}: ${e.message}`);
            }
          }}
        />
      </Wrapper>
    )
  }
}

export default withTranslation()(TargetDateInput);