import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";

import {
  Button,
  Tooltip, Intent, 
  RadioGroup, Radio, 
  Spinner, 
  Checkbox, Position, Toaster, 
} from "@blueprintjs/core";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;
const Section = styled.div``;
const SectionTitle = styled.div`
  font-weight: bold;
`;
const SectionContent = styled.div`
  padding: 10px;
  margin-bottom: 1rem;
`;

interface SettingsGeneralTabProps {}

interface InjectedProps extends SettingsGeneralTabProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SettingsGeneralTab extends Component<SettingsGeneralTabProps> {
  @observable readyToShow = false;
  @action setReadyToShow(value: boolean) { this.readyToShow = value; }

  @observable language = i18n.language;
  @action setLanguage(value: string) { this.language = value; }

  constructor(props: SettingsGeneralTabProps) {
    super(props);
    makeObservable(this);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  async componentDidMount() {
    this.setReadyToShow(false);
    await this.trelloStore.fetchBoardSettings();
    this.setReadyToShow(true);    
  }

  saveSettings = async() => {
    try {
      this.setReadyToShow(false);
      await this.trelloStore.updateBoardSettings();
      Toaster.create({position: Position.BOTTOM}).show({ message: 'SUCCESS', intent: Intent.SUCCESS });
      this.setReadyToShow(true);
    } catch (e) {
      this.setReadyToShow(true);
      alert(`${e.name}: ${e.message}`);
    }
  }

  handleCollect = async() => {
    try {
      await this.trelloStore.collectTrelloData();
    } catch (e) {
      alert(`${e.name}: ${e.message}`);
    }
  }

  handleOptionChangedSendWeeklyReport = async () => {
    if(this.trelloStore.boardSettings.send_weekly_report_emails.includes(this.userStore.email)) {
      this.trelloStore.setBoardSettingsSendWeeklyReportEmails(
        this.trelloStore.boardSettings.send_weekly_report_emails.filter(x => x !== this.userStore.email)
      );
    } else {
      this.trelloStore.setBoardSettingsSendWeeklyReportEmails(
        this.trelloStore.boardSettings.send_weekly_report_emails.concat([this.userStore.email])
      );
    }
    await this.saveSettings();
  }

  render() {
    return (
      <Wrapper>
        {!this.readyToShow ? (
          <Spinner />
        ) : (
          <>
          <Section>
            <SectionTitle style={{display:'flex', lineHeight:'30px'}}>
              <div>{i18n.t("settings-general-datacollection")}</div>
              <Tooltip placement="bottom" content={
                <div>
                  <div>{i18n.t("settings-general-collectnow")}</div>
                </div>
              }>
                <Button 
                  intent={Intent.WARNING} 
                  icon="updated"
                  minimal
                  onClick={this.handleCollect}
                />
              </Tooltip>
            </SectionTitle>
            <SectionContent>
              <RadioGroup
                inline
                onChange={() => {}}
              >
                <Radio label="1 hour" disabled />
                <Radio label="1 day" checked />
              </RadioGroup>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle style={{display:'flex', lineHeight:'30px'}}>
              <div>{i18n.t("settings-general-weeklyreport")}</div>
            </SectionTitle>
            <SectionContent>
              <div>
                <div>
                  <Checkbox 
                    label={i18n.t("settings-general-registerreport")}
                    checked={this.trelloStore.boardSettings.send_weekly_report_emails.includes(this.userStore.email)}
                    onChange={() => {this.handleOptionChangedSendWeeklyReport()}}
                  />
                </div>
                <ul>
                  {this.trelloStore.boardSettings.send_weekly_report_emails.length > 0 ? (
                    <>
                    {this.trelloStore.boardSettings.send_weekly_report_emails.map(x => {
                      return (
                        <li key={x}>{x}</li>
                      );
                    })}
                    </>
                  ) : (
                    <li>{i18n.t("settings-general-empty")}</li>
                  )}
                </ul>
              </div>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle style={{display:'flex', lineHeight:'30px'}}>
              <div>{i18n.t("settings-general-language")}</div>
            </SectionTitle>
            <SectionContent>
              <RadioGroup
                inline
                selectedValue={this.language}
                onChange={(e: any) => {
                  i18n.changeLanguage(e.target.value);
                  this.setLanguage(e.target.value);
                  this.userStore.setLanguage(e.target.value);
                }}
              >
                <Radio label="한국어" value="kr" />
                <Radio label="English" value="en" />
              </RadioGroup>
            </SectionContent>          
          </Section>
          </>
        )}
      </Wrapper>
    )
  }
}

export default withTranslation()(SettingsGeneralTab);