import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {
  PanelStack,
} from "@blueprintjs/core";
import GraphStack from "./GraphStack";
import ListStack from "./ListStack";
import "./WorkflowView.css";

import { AppStore } from "../../../../stores/AppStore";


const Wrapper = styled.div`
  height: 100%;
`;

interface WorkflowViewProps {
  viewType: string;
}
interface InjectedProps extends WorkflowViewProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class WorkflowView extends Component<WorkflowViewProps> {
  @observable showHeader = false;
  @action setShowHeader(value: boolean) { this.showHeader = value; }

  constructor(props: WorkflowViewProps) {
    super(props);
    makeObservable(this);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    return (
      <Wrapper>
        <PanelStack
            className="h-100"
            initialPanel={{
              component: this.props.viewType === 'graph' ? GraphStack : ListStack,
              title: this.props.viewType === 'graph' ? 'GRAPH' : 'List',
              props: {},
            }}
            renderActivePanelOnly={false}
            showPanelHeader={this.showHeader}
            onOpen={() => {this.setShowHeader(true)}}
            onClose={() => {this.setShowHeader(false)}}
        />        
      </Wrapper>
    );
  }
}

export default WorkflowView;