import React, { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";

import { AppStore } from "../../stores/AppStore";
import GuestPanel from "./GuestPanel";
import MemberPanel from "./MemberPanel";

const Wrapper = styled.div``;

interface HomePageProps {}

interface InjectedProps extends HomePageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class HomePage extends Component<HomePageProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  render() {
    return (
      <Wrapper>
        {this.userStore.isLoggedIn ? (
          <MemberPanel />
        ) : (
          <GuestPanel />
        )}
      </Wrapper>
    )
  }
}

export default HomePage;