import { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { 
 } from "@blueprintjs/core";
import { 
  Pie
} from "react-chartjs-2";

import { withTranslation } from "react-i18next";
import i18n from "../../../../utils/i18n/i18n";

import { AppStore } from "../../../../stores/AppStore";


const Wrapper = styled.div``;
const ChartDiv = styled.div`
  width: 400px;
  border: 1px solid lightgray;
`;

interface ActionByMemberProps {}

interface InjectedProps extends ActionByMemberProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ActionByMember extends Component<ActionByMemberProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  labels(member_ids: Array<string>) {
    return member_ids.map(member_id => {
      const member = this.trelloStore.selectableActionBys.find(x => x.member_id === member_id);
      return member ? member.fullname : '';
    })
  }

  colors(member_ids: Array<string>) {
    return member_ids.map(member_id => {
      const member = this.trelloStore.selectableActionBys.find(x => x.member_id === member_id);
      return member ? member.chart_color : 'lightgray';
    })
  }

  render() {
    const data = JSON.parse(this.trelloStore.highlightsData);

    return (
      <Wrapper>
        <ChartDiv>
          <Pie 
            width={2}
            height={1}

            data={{
              labels: this.labels(data.action_by_member.member_ids),
              datasets: [{
                label: '# of actions',
                data: data.action_by_member.num_actions,
                backgroundColor: this.colors(data.action_by_member.member_ids),
              }]            
            }}
            options={{
              title: {
                display: true,
                text: i18n.t("highlights-chart-actionbymember")
              },
              legend: { 
                display: data.action_by_member.member_ids.length <= 5,
                position: 'left', 
                // align: 'start' 
              },
            }}
          />
        </ChartDiv>
      </Wrapper>
    )
  }
}

export default withTranslation()(ActionByMember);