import { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";

import {
} from "@blueprintjs/core";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;
const Section = styled.div``;
const SectionTitle = styled.div`
  font-weight: bold;
`;
const SectionContent = styled.div`
  padding: 10px;
  margin-bottom: 1rem;
`;

interface TicketInUserTabProps {}

interface InjectedProps extends TicketInUserTabProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TicketInUserTab extends Component<TicketInUserTabProps> {
  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }

  render() {
    return (
      <Wrapper>
        <Section>
          <SectionTitle>TBD</SectionTitle>
          <SectionContent>&nbsp;</SectionContent>
        </Section>
      </Wrapper>
    )
  }
}

export default TicketInUserTab;