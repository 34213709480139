import { FC } from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  margin-top: 120px;
  text-align: center;
`;
const Title = styled.h1`
  font-size: 4rem;
`;
const Text = styled.h2``;

const InfoMessage: FC<{
  title: string,
  text: string
}> = ({ title, text }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Wrapper>
  );
}

export default InfoMessage;