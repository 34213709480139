import React, { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";

import {
  HTMLTable,
  Button,
} from "@blueprintjs/core";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";
import moment from "moment";


const Wrapper = styled.div``;
const Section = styled.div``;
const SectionTitle = styled.div`
  font-weight: bold;
`;
const SectionContent = styled.div`
  padding: 10px;
  margin-bottom: 1rem;
`;

interface SettingsTicketTabProps {}

interface InjectedProps extends SettingsTicketTabProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SettingsTicketTab extends Component<SettingsTicketTabProps> {
  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  renderMember(member_id: string) {
    if(member_id === '') {
      return 'seeso team (welcome)';
    }

    let member = this.trelloStore.selectableActionBys.find(x => x.member_id === member_id)
    if(member) {
      return `${member.fullname} (${member.username})`;
    } else {
      return 'unknown';
    }
  }

  render() {
    return (
      <Wrapper>
        <Section>
          <SectionTitle>{i18n.t("settings-ticket-status")}</SectionTitle>
          <SectionContent>
            <div>{i18n.t("settings-ticket-expiration")}: {this.trelloStore.expirationDate}</div>
            <div style={{paddingTop:'10px'}}>
              <Button 
                text={i18n.t("settings-ticket-expbtn")}
                onClick={async () => {
                  if(moment(this.trelloStore.expirationDate).diff(moment(), 'days') > 7) {
                    alert(`Tickets can be issued 7 days before the expiration date.`);
                    return;
                  } else {
                    await this.trelloStore.issueTicket();
                  }
                }}
              />
            </div>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>{i18n.t("settings-ticket-history")}</SectionTitle>
          <SectionContent>
            <HTMLTable
              striped
              style={{width:"100%"}}
            >
              <thead>
                <tr>
                  <th>{i18n.t("settings-ticket-no")}</th>
                  <th>{i18n.t("settings-ticket-id")}</th>
                  <th>{i18n.t("settings-ticket-issuedby")}</th>
                  <th>{i18n.t("settings-ticket-issueddate")}</th>
                  <th>{i18n.t("settings-ticket-servicedates")}</th>
                </tr>
              </thead>
              <tbody>
                {this.trelloStore.tickets.map((ticket, idx) => {
                  return (
                    <tr key={ticket.ticket_id}>
                      <td>{this.trelloStore.tickets.length - idx}</td>
                      <td>{ticket.ticket_id}</td>
                      <td>{this.renderMember(ticket.issue_by)}</td>
                      <td>{ticket.issue_date}</td>
                      <td>{ticket.service_start_date} ~ {ticket.service_end_date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </HTMLTable>
          </SectionContent>
        </Section>
      </Wrapper>
    )
  }
}

export default withTranslation()(SettingsTicketTab);