import { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { 
 } from "@blueprintjs/core";
import { 
  Line
} from "react-chartjs-2";

import { withTranslation } from "react-i18next";
import i18n from "../../../../utils/i18n/i18n";

import { AppStore } from "../../../../stores/AppStore";


const Wrapper = styled.div``;
const ChartDiv = styled.div`
  min-width: 800px;
  border: 1px solid lightgray;
`;

interface ActionByDateProps {}

interface InjectedProps extends ActionByDateProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ActionByDate extends Component<ActionByDateProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    const data = JSON.parse(this.trelloStore.highlightsData);

    return (
      <Wrapper>
        <ChartDiv>
          <Line 
            width={8}
            height={2}

            data={{
              labels: data.action_dates,
              datasets: [
                {
                  label: i18n.t("highlights-chart-current"),
                  data: data.action_by_date.current,
                  borderColor: '#39a2db',
                  backgroundColor: '#39a2db44',
                },
                {
                  label: i18n.t("highlights-chart-previous"),
                  data: data.action_by_date.previous,
                  borderColor: '#e8f0f2',
                  borderDash: [20, 5],
                  backgroundColor: '#e8f0f244',
                },
              ]
            }}
            options={{
              title: {
                display: true,
                text: i18n.t("highlights-chart-actionbydate")
              },
              scales: {
                xAxes: [{
                  type: "time",
                  time: {
                    unit: 'day',
                    unitStepSize: 1,
                    tooltipFormat: "YYYY-MM-DD",
                    displayFormats: {
                      day: "MM/DD",
                      month: "MM/DD",
                    }
                  },
                  ticks: {
                    major: {
                      enabled: true,
                      fontColor: 'red',
                    }
                  },
                  afterBuildTicks: (scale: any, ticks: any) => {
                    ticks.forEach((t: any) => {
                      const day = new Date(t.value).getDay();
                      t.major = (day === 0);
                    });
                    return ticks;
                  },
                }],
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                  },
                }],
              },
              legend: { 
                position: 'left', 
              },
            }}
          />

        </ChartDiv>
      </Wrapper>
    )
  }
}

export default withTranslation()(ActionByDate);