import { Component } from "react";

import { inject, observer } from "mobx-react";

import { RouteComponentProps, withRouter } from "react-router";

import styled from "styled-components/macro";
import { 
  Tab, Tabs,
} from "@blueprintjs/core";

import BoardCurrentTab from "../tabs/BoardCurrentTab";
import BoardComparisonTab from "../tabs/BoardComparisonTab";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;


interface PageParams {
  boardId: string;
  tabId: string;
}
interface BoardPanelProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends BoardPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class BoardPanel extends Component<BoardPanelProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    const { boardId, tabId } = this.props.match.params;
    return (
      <Wrapper>
        <Tabs
          id="tabs-board"
          onChange={(newTabId) => {this.props.history.push(`/trello/${boardId}/board/${newTabId}`)}}
          selectedTabId={tabId ? tabId : 'current'}
        >
          <Tab id="current" title={i18n.t("board-tabtitle-current")} panel={(<BoardCurrentTab />)} />
          <Tab id="comparison" title={i18n.t("board-tabtitle-comparison")} panel={(<BoardComparisonTab />)} />
        </Tabs>         
      </Wrapper>
    )
  }
}

export default withTranslation()(withRouter(BoardPanel));