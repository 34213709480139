import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { 
  Navbar, NavbarGroup, NavbarHeading, NavbarDivider, Button, FocusStyleManager, 
  Intent, Menu, MenuItem, Tooltip,
} from "@blueprintjs/core";
import { Select, ItemRenderer, ItemListRenderer, ItemPredicate } from "@blueprintjs/select";
import "./BasePage.css";

import { AppStore } from "../../stores/AppStore";

import { withTranslation } from "react-i18next";
import i18n from "../../utils/i18n/i18n";

const Wrapper = styled.div``;
const Content = styled.div``;
const ContentWithNavBar = styled(Content)`
  padding-top: 50px;
  min-height: calc(100vh - 130px);
`;
const NavRight = styled.div`display: flex;`;


interface BasePageProps extends RouteComponentProps {
}

interface InjectedProps extends BasePageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class BasePage extends Component<BasePageProps> {
  @observable fadeOut:boolean = false;
  @action setFadeOut(value: boolean) { this.fadeOut = value; }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  constructor(props: BasePageProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();
  }

  handleBoardChanged = async(board_id: string) => {
    this.trelloStore.setSelectedBoardId(board_id);
    if(board_id === '') {
      this.props.history.push('/');
    } else {
      try {
        await this.trelloStore.analyzeTrelloData();
        this.props.history.push(`/trello/${board_id}/highlights`);
      } catch(e) {
        alert(`${e.name}: ${e.message}`);
      }
    }
  }

  handleLogout = () => {
    this.setFadeOut(true);
    setTimeout(() => {
      this.userStore.logout(); 
      this.trelloStore.logout();
      this.props.history.push('/');
      this.setFadeOut(false);
    }, 1000);
  }

  filterBoard: ItemPredicate<any> = (query, item, _index, exactMatch) => {
    const nomalized_label = item.label.toLowerCase();
    const nomalized_query = query.toLowerCase();

    return nomalized_label.indexOf(nomalized_query) >= 0;
  }

  renderBoardList: ItemListRenderer<any> = ({ items, itemsParentRef, query, renderItem }) => {
    const renderedItems = items.map(renderItem).filter(item => item != null);
    return (
        <Menu ulRef={itemsParentRef} style={{maxWidth:'800px'}}>
            <MenuItem
                disabled={true}
                text={`Found ${renderedItems.length} items matching "${query}"`}
            />
            {renderedItems}
        </Menu>
    );
  };
  renderBoard: ItemRenderer<any> = (item, {modifiers, handleClick}) => {
    if(!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={item.board_id}
        active={modifiers.active}
        icon={'tick'}
        text={item.label}
        onClick={handleClick}
      />
    )
  }

  render() {
    return (
      <Wrapper
        className={this.fadeOut ? "fade-out" : ""}
      >
        {this.userStore.isLoggedIn && (
        <Navbar fixedToTop={true}>
          <NavbarGroup align="left">
            <NavbarHeading>
              <Button minimal large icon="time" 
                onClick={() => {
                  this.trelloStore.setSelectedBoardId('');
                  this.props.history.push('/');
                }}
              >
                  <strong>Time Machine</strong>
              </Button>
            </NavbarHeading>

            <NavbarDivider style={{marginRight:'40px'}} />

            <div>
            <Select 
              items={this.trelloStore.selectableBoards.map(board => {
                return {label: `${board.workspace_name} - ${board.board_name}`, board_id: board.board_id}
              })}
              className="w-100"
              noResults={<MenuItem disabled={true} text="No results" />}
              itemsEqual="board_id"
              itemListRenderer={this.renderBoardList}
              itemRenderer={this.renderBoard}
              itemPredicate={this.filterBoard}
              onItemSelect={item => { this.handleBoardChanged(item.board_id); }}
            >
              <Button
                minimal
                icon="search"
                text={this.trelloStore.selectedBoardId ? `${this.trelloStore.selectedBoard.workspace_name} - ${this.trelloStore.selectedBoard.board_name}` : 'Board'}
              />
            </Select>
            </div>

            <div style={{display: this.trelloStore.selectedBoardId === '' ? 'none' : 'block'}}>
              <Tooltip placement="bottom" content={
                <div>
                  <div>Go to Trello</div>
                </div>
              }>
                <Button 
                  intent={Intent.PRIMARY} 
                  icon="link"
                  minimal
                  onClick={() => {
                    window.open(this.trelloStore.selectedBoard.board_url, '_blank');
                  }}
                />
              </Tooltip>
            </div>                
          </NavbarGroup>

          <NavbarGroup align="right">
            <NavRight>
              <div style={{lineHeight:'30px', marginRight:'10px'}}>{this.userStore.email}</div>
              {/* <Button 
                minimal
                text={this.userStore.email}
                // onClick={() => {this.props.history.push("/mypage")}}
              /> */}
              <Button minimal icon="log-out" text={i18n.t("menu-logout")} onClick={() => {this.handleLogout()}} 
              />
            </NavRight>
          </NavbarGroup>
        </Navbar>
        )}

        {!this.userStore.isLoggedIn ? (
          <Content>
            {this.props.children}
          </Content>
        ) : (
          <ContentWithNavBar>
            {this.props.children}
          </ContentWithNavBar>
        )}
      </Wrapper>
    )
  }
}

export default withTranslation()(withRouter(BasePage));