import { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { 
 } from "@blueprintjs/core";
import { 
  Line
} from "react-chartjs-2";

import { withTranslation } from "react-i18next";
import i18n from "../../../../utils/i18n/i18n";

import { AppStore } from "../../../../stores/AppStore";


const Wrapper = styled.div``;
const ChartDiv = styled.div`
  min-width: 800px;
  border: 1px solid lightgray;
`;

interface PerformanceCheckItemsProps {}

interface InjectedProps extends PerformanceCheckItemsProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class PerformanceCheckItems extends Component<PerformanceCheckItemsProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    const data = JSON.parse(this.trelloStore.highlightsData);

    return (
      <Wrapper>
        <ChartDiv>
          <Line 
            width={4}
            height={1}

            data={{
              labels: data.action_dates,
              datasets: [
                {
                  label: 'COMPLETED',
                  data: data.checkitem_completed.sum_by_date,
                  borderColor: '#8fbc8f',
                  backgroundColor: '#8fbc8faa',
                  // fill: false,
                },
              ]
            }}
            options={{
              title: {
                display: true,
                text: i18n.t("highlights-chart-checkitems")
              },
              scales: {
                xAxes: [{
                  type: "time",
                  time: {
                    unit: 'day',
                    unitStepSize: 1,
                    tooltipFormat: "YYYY-MM-DD",
                    displayFormats: {
                      day: "MM/DD",
                      month: "MM/DD",
                    }
                  },
                  ticks: {
                    major: {
                      enabled: true,
                      fontColor: 'red',
                    }
                  },
                  afterBuildTicks: (scale: any, ticks: any) => {
                    ticks.forEach((t: any) => {
                      const day = new Date(t.value).getDay();
                      t.major = (day === 0);
                    });
                    return ticks;
                  },
                }],
                yAxes: [{
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                  },
                }],
              },
              legend: { 
                display: false,
                // position: 'top', 
                // reverse: true,
                // align: 'start' 
              },
            }}
          />

        </ChartDiv>
      </Wrapper>
    )
  }
}

export default withTranslation()(PerformanceCheckItems);