import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./translation.en.json";
import translationKr from "./translation.kr.json";

const resource = {
  en: {
    translation: translationEn,
  },
  kr: {
    translation: translationKr,
  },
};

export const PREF_LANGUAGE_KEY = "timemachine-pref-language";
const prefLanguage = window.localStorage.getItem(PREF_LANGUAGE_KEY);

i18n.use(initReactI18next).init({
  resources: resource,
  lng: prefLanguage !== null ? prefLanguage : "kr",
  fallbackLng: "kr",
  debug: true,
  keySeparator: false,
  interpolation: { escapeValue: false },
});

export default i18n;