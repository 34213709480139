import { Component } from "react";

import { inject, observer } from "mobx-react";

import { RouteComponentProps, withRouter } from "react-router";

import styled from "styled-components/macro";
import { 
  Tab, Tabs,
 } from "@blueprintjs/core";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

 import ProgressTab from "../tabs/ProgressTab";
 import ProgressCheckitemTab from "../tabs/ProgressCheckitemTab";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;

interface PageParams {
  boardId: string;
  tabId: string;
}
interface ProgressPanelProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends ProgressPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProgressPanel extends Component<ProgressPanelProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    const { boardId, tabId } = this.props.match.params;
    return (
      <Wrapper>
        <Tabs
          id="tabs-progress"
          onChange={(newTabId) => {this.props.history.push(`/trello/${boardId}/progress/${newTabId}`)}}
          selectedTabId={tabId ? tabId : ''}
        >
          <Tab id="" title={i18n.t("progress-tabtitle-progress")} panel={(<ProgressTab />)} />
          <Tab id="checkitem" title={i18n.t("progress-tabtitle-checkitem")} panel={(<ProgressCheckitemTab />)} />
        </Tabs>        
      </Wrapper>
    )
  }
}

export default withTranslation()(withRouter(ProgressPanel));