import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {
  Dialog, Classes, HTMLTable, HTMLSelect,
} from "@blueprintjs/core";

import { AppStore } from "../../../stores/AppStore";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import * as Constants from "../../../utils/Constants";


const Wrapper = styled.div``;

interface ListClassPopupProps {
  datasets: Array<{
    id: string,
    label: string,
    data: Array<number>,
    backgroundColor: string,
  }>;
  isOpen: boolean;
  onClose: () => void;
  onClassChanged: (list_id: string, class_before: string, class_after: string) => void;
}

interface InjectedProps extends ListClassPopupProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ListClassPopup extends Component<ListClassPopupProps> {
  @observable readyToShow: boolean = false;
  @action setReadyToShow(value: boolean) {this.readyToShow = value}

  private listColor: Array<{id: string, label: string, backgroundColor: string}> = [];

  constructor(props: ListClassPopupProps) {
    super(props);
    makeObservable(this);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  componentDidMount() {
    this.setReadyToShow(true);
  }

  handleOpened = () => {
    // componentDidMount 에서 수행할 경우 datasets 값의 변화를 반영하지 못하는 문제가 있음
    this.listColor = [];
    this.props.datasets.map(l => {
      this.listColor.push({
        id: l.id,
        label: l.label,
        backgroundColor: l.backgroundColor
      });
      return null;
    });

    // 아래와 같이 반영하지 않으면, 처음 띄울 때 리스트 목록을 뿌려주지 않음
    this.forceUpdate();
  }

  renderListItem = (l: any) => {
    if(l.id === 'deleted') { return null; }

    return (
      <tr key={l.id}>
        <td>{l.label}</td>
        <td>
          <HTMLSelect
            value={l.backgroundColor}
            onChange={(e) => {
              let class_before = Constants.CFD_LIST_TYPE_BY_COLOR(l.backgroundColor);
              let class_after = Constants.CFD_LIST_TYPE_BY_COLOR(e.target.value);

              l.backgroundColor = e.target.value;
              this.props.onClassChanged(l.id, class_before, class_after);

              // 아래와 같이 반영하지 않으면, 항목 선택 변화 시 화면에 표시 안됨
              this.forceUpdate();
            }}
          >
            <option value={Constants.CFD_LIST_COLOR.get('none')}>{i18n.t("popup-listclass-options-unknown")}</option>
            <option value={Constants.CFD_LIST_COLOR.get('info')}>{i18n.t("popup-listclass-options-info")}</option>
            <option value={Constants.CFD_LIST_COLOR.get('todo')}>{i18n.t("popup-listclass-options-todo")}</option>
            <option value={Constants.CFD_LIST_COLOR.get('doing')}>{i18n.t("popup-listclass-options-wip")}</option>
            <option value={Constants.CFD_LIST_COLOR.get('done')}>{i18n.t("popup-listclass-options-done")}</option>            
          </HTMLSelect>
        </td>
      </tr>
    );
  }

  render() {
    if(!this.readyToShow) { return (<div></div>); }

    return (
      <Dialog
        title={i18n.t("popup-listclass-title")}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        onOpened={this.handleOpened}
        style={{}}
      >
        <Wrapper className={Classes.DIALOG_BODY}>
          <HTMLTable
            style={{width:'100%'}}
          >
            <thead>
              <tr>
                <th>{i18n.t("popup-listclass-th-list")}</th>
                <th>{i18n.t("popup-listclass-th-class")}</th>
              </tr>
            </thead>
            <tbody>
              {this.listColor.map(l => {
                return this.renderListItem(l);
              })}
            </tbody>
          </HTMLTable>
        </Wrapper>
      </Dialog>
    )
  }
}

export default withTranslation()(ListClassPopup);