import { types, flow } from "mobx-state-tree";
import axios from "axios";
import moment from "moment";

import { 
  SelectableBoardModel, BoardSettingsModel,
  MemberModel, LabelModel, SelectableListModel, ListModel, CardModel, ActionModel, ChecklistModel, CheckitemModel,
  TicketModel,
} from "./models/TrelloBoard";

import * as Constants from "../utils/Constants";

export const TrelloStoreModel = types
  .model("TrelloStore", {

    // 상수
    CONST_TRELLO_API_KEY: '3d95110a56f334f99ac94b820aab9f5b',
    CONST_WF_PATTERN_NODE_DELIMETER: '$NODE$',
    CONST_WF_PATTERN_ROOT_NODE_ID: 'NO ACTION',
    CONST_TASK_TYPE_COLLECT: 'COLLECT',
    CONST_TASK_TYPE_ANALYZE: 'ANALYZE',
    CONST_ID_LIST_DELETED: 'deleted',

    // 비동기 작업 관련 변수
    isBusy: types.optional(types.boolean, false),
    isBusyChecking: types.optional(types.boolean, false),  // status 체크할 때 다운로드로 인해 시간이 오래걸리는 현상 처리
    taskId: types.optional(types.string, ''),
    taskType: types.optional(types.string, ''),
    taskSeconds: types.optional(types.number, 0),

    // 보드 관리
    selectableBoards: types.optional(types.array(SelectableBoardModel), []),
    selectedBoardId: types.optional(types.string, ''),

    // 보드 설정 관리
    boardSettings: types.optional(BoardSettingsModel, {}),

    // 로그 필터링 옵션 관리
    selectableDates: types.optional(types.array(types.string), []),
    startDate: types.optional(types.string, ''),
    targetDate: types.optional(types.string, ''),
    minDate: types.optional(types.string, ''),
    maxDate: types.optional(types.string, ''),
    selectedActionByIds: types.optional(types.array(types.string), []),
    selectedActionByAll: types.optional(types.boolean, true),
    selectableActionBys: types.optional(types.array(MemberModel), []),
    filterActionBysOpened: types.optional(types.boolean, true),
    selectedMemberIds: types.optional(types.array(types.string), []),
    selectedMemberAll: types.optional(types.boolean, true),
    selectableMembers: types.optional(types.array(MemberModel), []),
    filterMembersOpened: types.optional(types.boolean, true),
    selectedLabelIds: types.optional(types.array(types.string), []),
    selectedLabelAll: types.optional(types.boolean, true),
    selectableLabels: types.optional(types.array(LabelModel), []),
    filterLabelsOpened: types.optional(types.boolean, true),
    selectedListIds: types.optional(types.array(types.string), []),
    selectedListAll: types.optional(types.boolean, true),
    selectableLists: types.optional(types.array(SelectableListModel), []),
    filterListsOpened: types.optional(types.boolean, true),

    // 모델 변수
    lists: types.optional(types.array(ListModel), []),
    cards: types.optional(types.array(CardModel), []),
    checklists: types.optional(types.array(ChecklistModel), []),

    // 분석결과 관련
    isAnalyzed: types.optional(types.boolean, false),
    actionTableData: types.optional(types.array(ActionModel), []),
    actionTrendsData: types.optional(types.string, ''),
    actionMemberData: types.optional(types.string, ''),
    boardStartDateCards: types.optional(types.array(CardModel), []),
    boardStartDateChecklists: types.optional(types.array(ChecklistModel), []),
    progressFlowData: types.optional(types.string, ''),
    progressCheckitemData: types.optional(types.string, ''),
    workflowPatternsData: types.optional(types.string, ''),
    highlightsData: types.optional(types.string, ''),

    // 티켓 관련
    expirationDate: types.optional(types.string, ''),
    tickets: types.optional(types.array(TicketModel), []),
  })
  .views(self => ({
    // 보드 선택 관련
    get selectedBoard() {
      let selected_board = self.selectableBoards.find(x => x.board_id === self.selectedBoardId);
      return selected_board ? selected_board : SelectableBoardModel.create({});
    },

    // 티켓 관련
    get isSelectedBoardExpired() {
      return moment().isAfter(moment(self.expirationDate), 'day');
    }
  }))
  .actions(self => ({

    // 비동기 작업 관련 변수
    setIsBusy(value: boolean) { self.isBusy = value; },
    setIsBusyChecking(value: boolean) { self.isBusyChecking = value; },
    setTaskId(value: string) { self.taskId = value; },
    setTaskType(value: string) { self.taskType = value; },
    setTaskSeconds(value: number) { self.taskSeconds = value; },

    // 보드 선택 관련
    setSelectedBoardId(value: string) { 
      self.selectedBoardId = value; 
      // 보드가 변경되면 리셋이 필요
      self.isAnalyzed = false;
      self.startDate = '';
      self.targetDate = '';
      self.selectedActionByIds.replace([]);
      self.selectedActionByAll = true;
      self.filterActionBysOpened = true;
      self.selectedMemberIds.replace([]);
      self.selectedMemberAll = true;
      self.filterMembersOpened = true;
      self.selectedLabelIds.replace([]);
      self.selectedLabelAll = true;
      self.filterLabelsOpened = true;
      self.selectedListIds.replace([]);
      self.selectedListAll = true;
      self.filterListsOpened = true;
    },

    // 필터 관리
    setStartDate(value: string | Date) { self.startDate = moment(value).format('YYYY-MM-DD'); },
    setTargetDate(value: string | Date) { self.targetDate = moment(value).format('YYYY-MM-DD'); },
    changeSelectedActionByIds(value: string) {
      if(self.selectedActionByIds.includes(value)) {
        self.selectedActionByIds.replace(self.selectedActionByIds.filter(x => x !== value));
      } else {
        self.selectedActionByIds.replace(self.selectedActionByIds.concat([value]));
      }
      self.selectedActionByAll = self.selectedActionByIds.length > 0 && self.selectedActionByIds.length === self.selectableActionBys.length;
    },
    changeSelectedActionByAll(value: boolean) { 
      self.selectedActionByAll = value; 
      if(value) {
        self.selectedActionByIds.replace(self.selectableActionBys.map(x => x.member_id));
      } else {
        self.selectedActionByIds.replace([]);
      }
    },
    setFilterActionBysOpened(value: boolean) { self.filterActionBysOpened = value; },
    changeSelectedMemberIds(value: string) {
      if(self.selectedMemberIds.includes(value)) {
        self.selectedMemberIds.replace(self.selectedMemberIds.filter(x => x !== value));
      } else {
        self.selectedMemberIds.replace(self.selectedMemberIds.concat([value]));
      }
      self.selectedMemberAll = self.selectedMemberIds.length > 0 && self.selectedMemberIds.length === self.selectableMembers.length;
    },
    changeSelectedMemberAll(value: boolean) { 
      self.selectedMemberAll = value; 
      if(value) {
        self.selectedMemberIds.replace(self.selectableMembers.map(x => x.member_id));
      } else {
        self.selectedMemberIds.replace([]);
      }
    },
    setFilterMembersOpened(value: boolean) { self.filterMembersOpened = value; },
    changeSelectedLabelIds(value: string) {
      if(self.selectedLabelIds.includes(value)) {
        self.selectedLabelIds.replace(self.selectedLabelIds.filter(x => x !== value));
      } else {
        self.selectedLabelIds.replace(self.selectedLabelIds.concat([value]));
      }
      self.selectedLabelAll = self.selectedLabelIds.length > 0 && self.selectedLabelIds.length === self.selectableLabels.length;
    },
    changeSelectedLabelAll(value: boolean) { 
      self.selectedLabelAll = value; 
      if(value) {
        self.selectedLabelIds.replace(self.selectableLabels.map(x => x.label_id));
      } else {
        self.selectedLabelIds.replace([]);
      }
    },
    setFilterLabelsOpened(value: boolean) { self.filterLabelsOpened = value; },
    changeSelectedListIds(value: string) {
      if(self.selectedListIds.includes(value)) {
        self.selectedListIds.replace(self.selectedListIds.filter(x => x !== value));
      } else {
        self.selectedListIds.replace(self.selectedListIds.concat([value]));
      }
      self.selectedListAll = self.selectedListIds.length > 0 && self.selectedListIds.length === self.selectableLists.length;
    },
    changeSelectedListAll(value: boolean) { 
      self.selectedListAll = value; 
      if(value) {
        self.selectedListIds.replace(self.selectableLists.map(x => x.list_id));
      } else {
        self.selectedListIds.replace([]);
      }
    },
    setFilterListsOpened(value: boolean) { self.filterListsOpened = value; },

    setFilterParams(value: string) {
      const data = JSON.parse(value);
      
      // 날짜 처리
      self.selectableDates.replace(data.selectable_dates.slice());      
      self.minDate = moment(data.min_date).format('YYYY-MM-DD');
      self.maxDate = moment(data.max_date).format('YYYY-MM-DD');
      self.startDate = moment(data.start_date).format('YYYY-MM-DD');
      self.targetDate = moment(data.target_date).format('YYYY-MM-DD');

      // action by 멤버 처리
      self.selectedActionByIds.replace(data.selected_actionby_ids);
      self.selectableActionBys.replace(data.selectable_actionbys.map((x: any) => {
        return MemberModel.create({
          member_id: x.member_id,
          fullname: x.fullname,
          username: x.username,
          chart_color: x.chart_color,
          num_cards: x.num_cards,
          num_actions: x.num_actions,
          date_last_activity: x.date_last_activity,
        });
      }));      

      // card 멤버 처리
      self.selectedMemberIds.replace(data.selected_member_ids);
      self.selectableMembers.replace(data.selectable_members.map((x: any) => {
        return MemberModel.create({
          member_id: x.member_id,
          fullname: x.fullname,
          username: x.username,
          chart_color: x.chart_color,
          num_cards: x.num_cards,
          num_actions: x.num_actions,
          date_last_activity: x.date_last_activity,
        });
      }));

      // 라벨 처리
      self.selectedLabelIds.replace(data.selected_label_ids);
      self.selectableLabels.replace(data.selectable_labels.map((x: any) => {
        return LabelModel.create({
          label_id: x.label_id,
          name: x.name,
          color: x.color ? x.color : 'no-color',
          num_cards: x.num_cards,
          num_actions: x.num_actions,
          date_last_activity: x.date_last_activity,
        });
      }));

      // 리스트 처리
      self.selectedListIds.replace(data.selected_list_ids);
      self.selectableLists.replace(data.selectable_lists.map((x: any) => {
        return SelectableListModel.create({
          list_id: x.list_id,
          name: x.name,
          num_cards: x.num_cards,
          num_actions: x.num_actions,
          date_last_activity: x.date_last_activity,
        });
      }));
    },
    setModelData(value: string) {
      const data = JSON.parse(value);

      // 리스트 모델
      self.lists.replace(data.lists.map((x: any) => {
        return ListModel.create({
          list_id: x.list_id,
          name: x.name,
          pos: x.pos,
          closed: x.closed,
        });
      }));

      // 카드 모델
      self.cards.replace(data.cards.map((x: any) => {
        return CardModel.create({
          card_id: x.card_id,
          name: x.name,
          desc: x.desc,
          due: x.due,
          due_complete: x.due_complete,
          id_list: x.id_list,
          id_labels: x.id_labels,
          id_members: x.id_members,
          id_checklists: x.id_checklists,
          filter: x.filter,
          date_last_activity: x.date_last_activity,
          short_link: x.short_link,
          num_comments: x.num_comments,
        });
      }));

      // 체크리스트 모델
      self.checklists.replace(data.checklists.map((x: any) => {
        return ChecklistModel.create({
          checklist_id: x.checklist_id,
          name: x.name,
          pos: x.pos,
          checkitems: x.checkitems.map((item:any) => {
            return CheckitemModel.create({
              checkitem_id: item.checkitem_id,
              name: item.name,
              state: item.state,
              pos: item.pos,
            });
          })
        });
      }));
    },

    // 보드 설정 관련
    setBoardSettingsSendWeeklyReportEmails(value: string[]) { self.boardSettings.send_weekly_report_emails.replace(value); },

    // 분석 결과 관련
    setIsAnalyzed(value: boolean) { self.isAnalyzed = value; },
    setActionTableData(data: any) {
      self.actionTableData.replace(data.map((x: any) => {
        return ActionModel.create({
          action_id: x.action_id,
          card_id: x.card_id,
          date: x.date,
          action_by: x.action_by,
          member: x.member,
          list: x.list,
          card: x.card,
          label: x.label,
          desc: x.desc,
        });
      }));      
    },
    setActionTrendsData(value: string) { self.actionTrendsData = value; },
    setActionMemberData(value: string) { self.actionMemberData = value; },
    setBoardStartDateCards(data: any) {
      self.boardStartDateCards.replace(data.map((x: any) => {
        return CardModel.create({
          card_id: x.card_id,
          name: x.name,
          due: x.due,
          due_complete: x.due_complete,
          id_list: x.id_list,
          id_labels: x.id_labels,
          id_members: x.id_members,
          id_checklists: x.id_checklists,
          filter: x.filter,
          date_last_activity: x.date_last_activity,
          short_link: x.short_link,
          num_comments: x.num_comments,          
        });
      }));
    },
    setBoardStartDateChecklists(data: any) {
      self.boardStartDateChecklists.replace(data.map((x: any) => {
        return ChecklistModel.create({
          checklist_id: x.checklist_id,
          name: x.name,
          pos: x.pos,
          checkitems: x.checkitems.map((item:any) => {
            return CheckitemModel.create({
              checkitem_id: item.checkitem_id,
              name: item.name,
              state: item.state,
              pos: item.pos,
            });
          })
        });
      }));
    },
    setProgressFlowData(value: string) { self.progressFlowData = value; },
    setProgressCheckitemData(value: string) { self.progressCheckitemData = value; },
    setWorkflowPatternsData(value: string) { self.workflowPatternsData = value; },
    setHighlightsData(value: string) { self.highlightsData = value; },

    setTicketData(data: any) {
      self.expirationDate = data.expiration_date;
      self.tickets.replace(data.tickets.map((t: any) => {
        return TicketModel.create({
          ticket_id: t.ticket_id,
          ticket_type: t.ticket_type,
          ticket_price: t.ticket_price,
          issue_by: t.issue_by,
          issue_date: t.issue_date,
          target_board_id: t.target_board_id,
          service_start_date: t.service_start_date,
          service_end_date: t.service_end_date,
        });
      }));
    },

    logout() {
      self.selectableBoards.clear();
      self.selectedBoardId = '';
    },
  }))
  .actions(self => {
    // 보드 선택 관련
    const fetchSelectableBoards = flow(function*() {
      try {

        const { data }: { data: any } = yield axios.get(
          `/trello/board/`
        );

        self.selectableBoards.replace(
          data.map((b: any) => {
            return SelectableBoardModel.create({
              board_id: b.board_id,
              board_url: b.board_url,
              board_name: b.board_name,
              workspace_name: b.workspace_name,
              background_image_url: b.background_image_url,
              last_activity_at: b.last_activity_at,
              updated_at: b.updated_at,
              expired_at: b.expired_at,
            });
          })          
        );

      } catch (e) {
        console.log("fetchSelectableBoards error", e);
        throw e;
      }
    });

    // 보드 설정 관련
    const fetchBoardSettings = flow(function*() {
      try {

        const { data }: { data: any } = yield axios.get(
          `/trello/board/${self.selectedBoardId}/settings/`
        );

        self.boardSettings = BoardSettingsModel.create({
          send_weekly_report_emails: data.send_weekly_report_emails,
        });

      } catch (e) {
        console.log("fetchBoardSettings error", e);
        throw e;
      }
    });
    const updateBoardSettings = flow(function*() {
      try {
        yield axios.post(
          `/trello/board/${self.selectedBoardId}/settings/`, {
            send_weekly_report_emails: self.boardSettings.send_weekly_report_emails,
          }
        );
      } catch (e) {
        console.log("updateBoardSettings error", e);
        throw e;
      }
    });
    

    // 데이터 수집 관련
    const collectTrelloData = flow(function*() {
      try {
        if(self.isBusy) { return; }

        const { data }: { data: any } = yield axios.get(
          `/trello/board/${self.selectedBoardId}/collect/`
        );
        self.setIsBusy(true);
        self.setTaskId(data.task_id);
        self.setTaskType(self.CONST_TASK_TYPE_COLLECT);
        self.setTaskSeconds(0);
      } catch (e) {
        console.log("collectTrelloData error", e);
        self.setIsBusy(false);
        self.setTaskId("");
        self.setTaskType("");
        self.setTaskSeconds(0);
        throw e;
      }
    });
    const collectTrelloDataStatus = flow(function*() {
      try {
        const { data }: { data: any } = yield axios.get(
          `/asynctask/get_status/${self.taskId}/`
        );

        if(data.task_status === 'SUCCESS' || data.task_status === 'FAILURE') {
          self.setIsBusy(false);
          self.setTaskId("");
          self.setTaskType("");
          self.setTaskSeconds(0);
        } else {
          self.setTaskSeconds(self.taskSeconds + 1);
        }

        return data.task_status;
      } catch (e) {
        console.log("collectTrelloDataStatus error", e);
        self.setIsBusy(false);
        self.setTaskId("");
        self.setTaskType("");
        self.setTaskSeconds(0);
        throw e;
      }
    });

    // 리스트 분류 관련
    const classifyList = flow(function*(list_id: string, class_before: string, class_after: string) {
      try {
        yield axios.post(
          `/trello/board/${self.selectedBoardId}/classify_list/`, {
            list_id: list_id,
            class_before: class_before,
            class_after: class_after,
          }
        );

        // 수정된 값을 프론트엔드에도 반영한다
        const data = JSON.parse(self.progressFlowData);
        const list_data = data.datasets.find((x: any) => x.id === list_id);
        if(list_data) {
          list_data.backgroundColor = Constants.CFD_LIST_COLOR.get(class_after);
          self.setProgressFlowData(JSON.stringify(data));
        }
      } catch (e) {
        console.log("classifyList error", e);
        throw e;
      }
    });

    // 분석 로직 관련
    const analyzeTrelloData = flow(function*() {
      try {
        if(self.isBusy) { return; }

        const { data }: { data: any } = yield axios.post(
          `/trello/board/${self.selectedBoardId}/analyze/`, {
            start_date: self.startDate,
            target_date: self.targetDate,
            selected_actionby_ids: self.selectedActionByIds,
            selected_actionby_all: self.selectedActionByAll,
            selected_member_ids: self.selectedMemberIds,
            selected_member_all: self.selectedMemberAll,
            selected_label_ids: self.selectedLabelIds,
            selected_label_all: self.selectedLabelAll,
            selected_list_ids: self.selectedListIds,
            selected_list_all: self.selectedListAll,
          }
        );
        self.setIsBusy(true);
        self.setTaskId(data.task_id);
        self.setTaskType(self.CONST_TASK_TYPE_ANALYZE);
        self.setTaskSeconds(0);
      } catch (e) {
        self.setIsBusy(false);
        self.setTaskId("");
        self.setTaskType("");
        self.setTaskSeconds(0);
        console.log("analyzeTrelloData error", e);
        throw e;
      }
    });
    const analyzeTrelloDataStatus = flow(function*() {
      try {
        const { data }: { data: any } = yield axios.get(
          `/asynctask/get_status/${self.taskId}/`
        );

        if(data.task_status === 'SUCCESS' || data.task_status === 'FAILURE') {
          self.setIsBusy(false);  // result 처리하는데 시간이 오래 걸리는 경우를 대비하여 미리 Busy를 바로 해제함

          if(data.task_status === 'SUCCESS') {
            if(data.task_result) {
              self.setFilterParams(data.task_result.filter_params);
              self.setModelData(data.task_result.model_data);
      
              self.setActionTableData(data.task_result.action_table_data);
              self.setActionTrendsData(data.task_result.action_trends_data);
              self.setActionMemberData(data.task_result.action_member_data);
              self.setBoardStartDateCards(data.task_result.board_start_date_cards);
              self.setBoardStartDateChecklists(data.task_result.board_start_date_checklists);
              self.setProgressFlowData(data.task_result.progress_flow_data);
              self.setProgressCheckitemData(data.task_result.progress_checkitem_data);
              self.setWorkflowPatternsData(data.task_result.workflow_patterns_data);
              self.setHighlightsData(data.task_result.highlights_data);
              self.setTicketData(data.task_result.ticket_data);
            }
          }
          self.setIsAnalyzed(true);
          self.setTaskId("");
          self.setTaskType("");
          self.setTaskSeconds(0);
        } else {
          self.setTaskSeconds(self.taskSeconds + 1);
        }
        return data.task_status;
      } catch (e) {
        console.log("analyzeTrelloDataStatus error", e);
        self.setIsBusy(false);
        self.setTaskId("");
        self.setTaskType("");
        self.setTaskSeconds(0);
        return 'FAILURE';
      }
    });

    // 티켓 관련
    const issueTicket = flow(function*() {
      try {
        const today = moment();
        let start_date = moment(self.expirationDate).add(1, 'day');
        let end_date = moment(self.expirationDate).add(7, 'day');

        // expired 상태에서 한참 있다가 issue 하는 경우 처리
        if(today.diff(start_date) > 0) {
          start_date = moment(today);
          end_date = moment(today).add(7, 'days');
        }

        const { data }: { data: any } = yield axios.post(
          `/trello/board/${self.selectedBoardId}/issue-ticket/`, {
            issue_date: today.format("YYYY-MM-DD"),
            service_start_date: start_date.format("YYYY-MM-DD"),
            service_end_date: end_date.format("YYYY-MM-DD")
          }
        );

        self.setTicketData(data);
      } catch (e) {
        console.log("issueTicket error", e);
        throw e;
      }
    });    

    return {
      // 보드 선택 관련
      fetchSelectableBoards,

      // 보드 설정 관련
      fetchBoardSettings,
      updateBoardSettings,

      // 데이터 수집 관련
      collectTrelloData,
      collectTrelloDataStatus,

      // 리스트 분류 관련
      classifyList,

      // 분석 로직 관련
      analyzeTrelloData,
      analyzeTrelloDataStatus,

      // 티켓 관련
      issueTicket,
    };    
  })

type TrelloStoreModelType = typeof TrelloStoreModel.Type;
export interface TrelloStore extends TrelloStoreModelType {}
