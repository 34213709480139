import React from "react";

import { Provider } from "mobx-react";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";

import axios from "axios";

import styled from "styled-components/macro";

import Routes from "./Routes";
import ScrollToTop from "./utils/ScrollToTop";
import CheckLogin from "./utils/CheckLogin";

import { AppStoreModel } from "./stores/AppStore";

import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";


const Wrapper = styled.div`
  min-height: 100vh;
`;

const browserHistory = createBrowserHistory();
const routerStore = new RouterStore();
const history = syncHistoryWithStore(browserHistory, routerStore);

const rootStore = AppStoreModel.create({});

if (process.env.NODE_ENV === "development") {
  // DEV (surge)
  axios.defaults.baseURL = "http://seeso.iptime.org:60015";
  // axios.defaults.baseURL = "http://localhost:8000";
} else {
  // PRD (aws)
  axios.defaults.baseURL = "https://api.timemachine.seeso.kr";
}
if (process.env.REACT_APP_BASEURL) {
  axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
}

const App: React.FC = () => {
  return (
    <Provider routerStore={routerStore} appStore={rootStore}>
      <Wrapper className="App">
        <Router history={history}>
          <ScrollToTop>
            <CheckLogin>
              <Routes />
            </CheckLogin>
          </ScrollToTop>
        </Router>
      </Wrapper>
    </Provider>
  );
}

export default App;
