import { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";

import { AppStore } from "../../../stores/AppStore";
import WorkflowView from "../components/WorkflowGraphView/WorkflowView";


const Wrapper = styled.div`
  height: calc(100vh - 180px);
  border: 1px solid lightgrey;
`;


interface WorkflowGraphTabProps {}

interface InjectedProps extends WorkflowGraphTabProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class WorkflowGraphTab extends Component<WorkflowGraphTabProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    return (
      <Wrapper>
        <WorkflowView viewType="graph" />
      </Wrapper>
    )
  }
}

export default WorkflowGraphTab;