import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import moment from "moment";

import styled from "styled-components/macro";
import { 
  InputGroup, Button, 
  Checkbox, 
  HTMLSelect,
} from "@blueprintjs/core";
import DataTable from "react-data-table-component";
import CardDetailPopup from "../components/CardDetailPopup";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div`
  overflow: scroll;
`;

interface ActionsTableTabProps {}

interface InjectedProps extends ActionsTableTabProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ActionsTableTab extends Component<ActionsTableTabProps> {
  @observable isCardDetailPopupOpen = false;
  @action setIsCardDetailPopupOpen(value: boolean) { this.isCardDetailPopupOpen = value; }
  @observable selectedCardId = "";
  @action setSelectedCardId(value: string) { this.selectedCardId = value; }
  @observable filterText = "";
  @action setFilterText(value: string) { this.filterText = value; }
  @observable showListActions = false;
  @action setShowListActions(value: boolean) { this.showListActions = value; }
  @observable filterActionBy = "";
  @action setFilterActionBy(value: string) { this.filterActionBy = value}
  @observable filterMember = "";
  @action setFilterMember(value: string) { this.filterMember = value}
  @observable filterLabel = "";
  @action setFilterLabel(value: string) { this.filterLabel = value}

  private columns = [
    {
      name: i18n.t("actions-table-date"),
      selector: "date",
      sortable: true,
      cell: (row: any) => <span>{moment(row.date).format("YYYY-MM-DD HH:mm:ss")}</span>,
    },
    {
      name: i18n.t("actions-table-card"),
      selector: "card",
      sortable: true
    },
    {
      name: i18n.t("actions-table-list"),
      selector: "list",
      sortable: true
    },
    {
      name: i18n.t("actions-table-actionby"),
      selector: "action_by",
      sortable: true
    },
    {
      name: i18n.t("actions-table-member"),
      selector: "member",
      sortable: true
    },
    {
      name: i18n.t("actions-table-label"),
      selector: "label",
      sortable: true
    },
    {
      name: i18n.t("actions-table-desc"),
      selector: "desc",
      sortable: true,
      maxWidth: '200px',
    },
  ];

  constructor(props: ActionsTableTabProps) {
    super(props);
    makeObservable(this);
  }
  
  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  handleRowSelected = (cardId: string | undefined) => {
    if(cardId) {
      this.setSelectedCardId(cardId);
      this.setIsCardDetailPopupOpen(true);
    }
  }

  handleExport = () => {
    this.exportToCSV(
      this.trelloStore.actionTableData.filter(x => this.checkFilters(x))
    );
  }

  convertArrayOfObjectsToCSV = (array: any) => {

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    // 결과변수를 컬럼명이 있는 head 로 초기화
    let result: string = keys.join(columnDelimiter) + lineDelimiter;

    let row_cnt = 1;
    array.forEach((item: any) => {
      let col_cnt = 0;
      let line = '';
      keys.forEach(key => {
        if (col_cnt > 0) { line += columnDelimiter; }

        // csv 에 출력할 값이 길다면 (20자 이상) 줄여준다 (특수문자없이 필요한 값만 들어가도록 임시 처리한 내용)
        let col_str: string = item[key];
        if(col_str.length > 20) {
          col_str = col_str.substring(0, 18) + '..'
        }
        col_str = col_str.replace(`\n`, ` `)  // 줄바꿈기호 처리
  
        line += key === 'action_id' ? row_cnt : `"${col_str}"`;
        
        col_cnt++;
      });
      result += line + lineDelimiter;
      row_cnt++;
    });
  
    return result;
  }  

  exportToCSV = (array: any) => {
    const link = document.createElement('a');
    let csv = this.convertArrayOfObjectsToCSV(array);
    if (csv == null) return;
  
    const filename = 'export.csv';
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();    
  }

  checkFilters = (x: any) => {
    let result = true;

    // 필터 텍스트 조건 처리
    result = result && [x.date,x.action_by,x.member,x.list,x.card,x.label,x.desc].join(' ').toLowerCase().includes(this.filterText.toLowerCase())

    // 리스트 액션을 보여주지 않을 경우 숨김 처리
    if(!this.showListActions) { result = result && x.card !== ''; }

    // action by 필터 처리
    if(this.filterActionBy !== '') { result = result && this.filterActionBy === x.action_by }

    // member 필터 처리
    if(this.filterMember !== '') { result = result && this.filterMember === x.member }

    // label 필터 처리
    if(this.filterLabel !== '') { result = result && this.filterLabel === x.label }

    return result;
  }

  render() {
    return (
      <Wrapper>
        <DataTable
          columns={this.columns}
          data={this.trelloStore.actionTableData.filter(x => this.checkFilters(x))}
          keyField="action_id"
          defaultSortField="date"
          noHeader
          pagination
          highlightOnHover
          pointerOnHover
          subHeader={true}
          subHeaderAlign={'left'}
          subHeaderWrap={false}
          subHeaderComponent={[
            <div key="dummy" style={{display:'flex', fontSize:'14px', width:'100%', justifyContent:'space-between'}}>
              <div><Button text={i18n.t("actions-table-export")} outlined onClick={this.handleExport} /></div>
              <div>
                <Checkbox 
                  label={i18n.t("actions-table-showlist")}
                  checked={this.showListActions}
                  onChange={() => {this.setShowListActions(!this.showListActions)}}
                  style={{margin:'0', lineHeight:'30px'}}
                />
              </div>

              <div>
                <HTMLSelect
                  value={this.filterActionBy}
                  onChange={(e: any) => {this.setFilterActionBy(e.target.value)}}
                >
                  <option value="">{i18n.t("actions-table-actionby")}</option>
                  {this.trelloStore.selectableActionBys.map(x => {
                    return (
                      <option key={x.member_id} value={x.fullname}>{x.fullname}</option>
                    );
                  })}                  
                </HTMLSelect>
              </div>
              <div>
                <HTMLSelect
                  value={this.filterMember}
                  onChange={(e: any) => {this.setFilterMember(e.target.value)}}
                >
                  <option value="">{i18n.t("actions-table-member")}</option>
                  {this.trelloStore.selectableMembers.map(x => {
                    return x.fullname === '' ? (
                      null
                    ) : (
                      <option key={x.member_id} value={x.fullname}>{x.fullname}</option>
                    );
                  })}
                </HTMLSelect>
              </div>
              <div>
                <HTMLSelect
                  value={this.filterLabel}
                  onChange={(e: any) => {this.setFilterLabel(e.target.value)}}
                >
                  <option value="">{i18n.t("actions-table-label")}</option>
                  {this.trelloStore.selectableLabels.map(x => {
                    return x.name === '' ? (
                      null
                    ) : (
                      <option key={x.label_id} value={x.name}>{x.name}</option>
                    );
                  })}                      
                </HTMLSelect>
              </div>
              <div>
                <InputGroup 
                  value={this.filterText}
                  placeholder={i18n.t("actions-table-searchtext")}
                  onChange={(e) => {this.setFilterText(e.currentTarget.value)}}
                />
              </div>
            </div>
          ]}
          onRowClicked={(row) => { this.handleRowSelected(row.card_id); }}
        />
        <CardDetailPopup 
          cardId={this.selectedCardId}
          isOpen={this.isCardDetailPopupOpen}
          onClose={() => {this.setIsCardDetailPopupOpen(false)}}
        />   
      </Wrapper>
    )
  }
}

export default withTranslation()(ActionsTableTab);