import { Component } from "react";

import { inject, observer } from "mobx-react";

import { RouteComponentProps, withRouter } from "react-router";

import styled from "styled-components/macro";
import { 
  Tab, Tabs,
} from "@blueprintjs/core";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";
 
import HighlightsTab from "../tabs/HighlightsTab";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;


interface PageParams {
  boardId: string;
  tabId: string;
}
interface HighlightsPanelProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends HighlightsPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class HighlightsPanel extends Component<HighlightsPanelProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    const { boardId, tabId } = this.props.match.params;

    return (
      <Wrapper>
        <Tabs
          id="tabs-highlights"
          onChange={(newTabId) => {this.props.history.push(`/trello/${boardId}/highlights/${newTabId}`)}}
          selectedTabId={tabId ? tabId : ''}
        >
          <Tab id="" title={i18n.t("highlights-tabtitle-highlights")} panel={(<HighlightsTab />)} />
        </Tabs>        
      </Wrapper>
    )
  }
}

export default withTranslation()(withRouter(HighlightsPanel));