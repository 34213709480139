import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { 
  Button,
  HTMLTable,
  Spinner,
} from "@blueprintjs/core";

import { withTranslation } from "react-i18next";
import i18n from "../../utils/i18n/i18n";

import { AppStore } from "../../stores/AppStore";

const Wrapper = styled.div`
  min-height: calc(100vh - 50px);
`;
const Content = styled.div`
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
`;

interface MemberPanelProps  extends RouteComponentProps {}

interface InjectedProps extends MemberPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class MemberPanel extends Component<MemberPanelProps> {
  @observable readyToShow = false;
  @action setReadyToShow(value: boolean) { this.readyToShow = value; }

  constructor(props: MemberPanelProps) {
    super(props);
    makeObservable(this);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  async componentDidMount() {
    try {
      await this.trelloStore.fetchSelectableBoards();
      this.setReadyToShow(true);
    } catch (e) {
      alert(`${e.name}: ${e.message}`);
    }
  }

  handleBoardClicked = async(board_id: string) => {
    this.trelloStore.setSelectedBoardId(board_id);
    if(board_id === '') {
      this.props.history.push('/');
    } else {
      try {
        await this.trelloStore.analyzeTrelloData();
        this.props.history.push(`/trello/${board_id}/highlights`);
      } catch(e) {
        alert(`${e.name}: ${e.message}`);
      }
    }
  }

  renderBoards = (boards: any) => {
    return (
      <HTMLTable
        style={{width:'100%'}}
        interactive
      >
        <thead>
          <tr>
            <th>{i18n.t("projects-th-background")}</th>
            <th>{i18n.t("projects-th-workspace")}</th>
            <th>{i18n.t("projects-th-board")}</th>
            <th style={{width:'150px', textAlign:'center'}}>{i18n.t("projects-th-last-activity")}</th>
            <th style={{width:'150px', textAlign:'center'}}>{i18n.t("projects-th-last-collection")}</th>
            <th style={{width:'150px', textAlign:'center'}}>{i18n.t("projects-th-ticket-expiration")}</th>
            <th style={{width:'100px', textAlign:'center'}}>{i18n.t("projects-th-source")}</th>
          </tr>
        </thead>
        <tbody>
          {boards.map((board: any) => {
            return (
              <tr key={board.board_id} onClick={() => {this.handleBoardClicked(board.board_id)}}>
                <td>
                  <img src={board.background_image_url} width={120} height={80} loading={"lazy"} alt={'Background'} />
                </td>
                <td style={{lineHeight:'30px', verticalAlign:'middle'}}>{board.workspace_name}</td>
                <td style={{lineHeight:'30px', verticalAlign:'middle'}}>{board.board_name}</td>
                <td style={{lineHeight:'30px', verticalAlign:'middle', textAlign:'center'}}>{board.last_activity_at}</td>
                <td style={{lineHeight:'30px', verticalAlign:'middle', textAlign:'center'}}>{board.updated_at}</td>
                <td style={{lineHeight:'30px', verticalAlign:'middle', textAlign:'center'}}>{board.expired_at}</td>
                <td style={{verticalAlign:'middle', textAlign:'center'}}>
                  <Button
                    text="Trello"
                    onClick={() => {window.open(board.board_url, '_blank');}}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    );
  }

  render() {
    return (
      <Wrapper>
        <Content>
          {this.readyToShow ? (
            <>
            <h2>{i18n.t("projects-title")}</h2>
            {this.trelloStore.selectableBoards.length === 0 ? (
              <h5>- No boards</h5>
            ) : this.renderBoards(this.trelloStore.selectableBoards)}
            </>
          ) : (
            <Spinner />
          )}
        </Content>
      </Wrapper>
    )
  }
}

export default withTranslation()(withRouter(MemberPanel));