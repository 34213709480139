import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {
  IPanelProps
} from "@blueprintjs/core";
import { Graph } from "./react-d3-graph-v2.6.0-d3-v5.5.0";
import CardsStack from "./CardsStack";

import { AppStore } from "../../../../stores/AppStore";


const Wrapper = styled.div``;

interface GraphStackProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class GraphStack extends Component<GraphStackProps & IPanelProps> {
  @observable readyToShow: boolean = false;
  @action setReadyToShow(value: boolean) { this.readyToShow = value; }

  private nodes: Array<any> = [];
  private links: Array<any> = [];
  private width: number = 0;
  private height: number = 0;

  constructor(props: GraphStackProps & IPanelProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const data = JSON.parse(this.trelloStore.workflowPatternsData);

    this.nodes = data.graph_data.nodes.slice();
    this.links = data.graph_data.links.slice();
    this.width = document.documentElement.clientWidth - 500;
    this.height = document.documentElement.clientHeight - 180;

    this.setReadyToShow(true);
  }  

  get userStore() { return this.props.appStore.userStore; }
  get trelloStore() { return this.props.appStore.trelloStore; }

  handleNodeClicked = (nodeId: string, node: any) => {
    this.props.openPanel({
      component: CardsStack,
      props: {
        appStore: this.props.appStore,
        nodeId: nodeId,
        cardIds: node.cards,
      },
      title: "CARDS",
    })
  }

  render() {
    return (
      <Wrapper>
        {this.readyToShow && (
          <Graph 
            id="graph-id"
            data={{
              nodes: this.nodes,
              links: this.links,
            }}
            config={{
              directed: true,
              nodeHighlightBehavior: true,
              highlightDegree: 0,  // 하위 노드/링크 하이라이트 효과 제거
              highlightOpacity: 0.2,
              width: this.width,
              height: this.height,
              node: {
                color: "red",
                size: 1,
                fontSize: 16,
                highlightColor: "blue",
                highlightFontSize: 24,
                labelProperty: "label",
                labelPosition: "top",
              },
              link: {
                highlightColor: "blue",
                renderLabel: true,
                labelProperty: "label",
                fontSize: 16,
                fontColor: 'lightgray',
              },
            }}
            onClickNode={this.handleNodeClicked}
          />
        )}
      </Wrapper>
    );
  }
}

export default GraphStack;