import { Component } from "react";

import { inject, observer } from "mobx-react";

import { RouteComponentProps, withRouter } from "react-router";

import styled from "styled-components/macro";
import { Button, Alignment, FocusStyleManager } from "@blueprintjs/core";

import { withTranslation } from "react-i18next";
import i18n from "../../utils/i18n/i18n";

import { AppStore } from "../../stores/AppStore";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 205px;
  min-height: calc(100vh - 50px);
  background: #f8f8f8;
  padding: 0px 25px 20px 25px;
`;
const MenuTitle = styled.h3`
  margin: 20px 0;
`;
const MenuDiv = styled.div``;

interface PageParams {
  menuId: string;
}
interface SideMenuProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends SideMenuProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SideMenu extends Component<SideMenuProps> {
  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();
  }

  render() {
    const {menuId} = this.props.match.params;

    return (
      <Wrapper>
          <MenuTitle>{i18n.t("menu-side-dashboard")}</MenuTitle>
          <MenuDiv>
          <Button 
            fill minimal large alignText={Alignment.LEFT} icon="lightbulb"
            disabled={this.trelloStore.isSelectedBoardExpired}
            onClick={() => {this.props.history.push(`/trello/${this.trelloStore.selectedBoardId}/highlights`)}}
          >
            {menuId === 'highlights' ? (<strong>{i18n.t("menu-side-highlights")}</strong>) : i18n.t("menu-side-highlights")}
          </Button>
          <Button 
            fill minimal large alignText={Alignment.LEFT} icon="projects"
            disabled={this.trelloStore.isSelectedBoardExpired}
            onClick={() => {this.props.history.push(`/trello/${this.trelloStore.selectedBoardId}/actions`)}}
          >
            {menuId === 'actions' ? (<strong>{i18n.t("menu-side-actions")}</strong>) : i18n.t("menu-side-actions")}
          </Button>
          <Button 
            fill minimal large alignText={Alignment.LEFT} icon="control"
            disabled={this.trelloStore.isSelectedBoardExpired}
            onClick={() => {this.props.history.push(`/trello/${this.trelloStore.selectedBoardId}/board`);}}
          >
            {menuId === 'board' ? (<strong>{i18n.t("menu-side-board")}</strong>) : i18n.t("menu-side-board")}
          </Button>
          <Button 
            fill minimal large alignText={Alignment.LEFT} icon="chart"
            disabled={this.trelloStore.isSelectedBoardExpired}
            onClick={() => {this.props.history.push(`/trello/${this.trelloStore.selectedBoardId}/progress`)}}
          >
            {menuId === 'progress' ? (<strong>{i18n.t("menu-side-progress")}</strong>) : i18n.t("menu-side-progress")}
          </Button>
          <Button 
            fill minimal large alignText={Alignment.LEFT} icon="data-lineage"
            disabled={this.trelloStore.isSelectedBoardExpired}
            onClick={() => {this.props.history.push(`/trello/${this.trelloStore.selectedBoardId}/workflow`)}}
          >
            {menuId === 'workflow' ? (<strong>{i18n.t("menu-side-workflow")}</strong>) : i18n.t("menu-side-workflow")}
          </Button>
        </MenuDiv>
        <MenuDiv><div style={{height:'1px', border:'1px solid lightgray', margin:'10px 0px'}} /></MenuDiv>
        <MenuDiv>
          <Button 
            fill minimal large alignText={Alignment.LEFT} icon="cog"
            onClick={() => {this.props.history.push(`/trello/${this.trelloStore.selectedBoardId}/settings`)}}
          >
            {menuId === 'settings' ? (<strong>{i18n.t("menu-side-settings")}</strong>) : i18n.t("menu-side-settings")}
          </Button>
        </MenuDiv>
      </Wrapper>
    )
  }
}

export default withTranslation()(withRouter(SideMenu));