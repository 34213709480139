import { Component } from "react";
import { Switch, Route } from "react-router";
import BasePage from "./pages/BasePage/BasePage";
import LoginProcPage from "./pages/LoginPage/LoginProcPage";
import HomePage from "./pages/HomePage/HomePage";
import MyPage from "./pages/MyPage/MyPage";
import TermsPage from "./pages/LegalPage/TermsPage";
import PrivacyPage from "./pages/LegalPage/PrivacyPage";
import TrelloPage from "./pages/TrelloPage/TrelloPage";
import TrelloPowerUpPage from "./pages/TrelloPage/TrelloPowerUpPage";

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/loginproc/" exact component={LoginProcPage} />
        <Route path="/trello/powerup/" exact component={TrelloPowerUpPage} />
        <Route path="/">
          <BasePage>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/terms-of-services/" exact component={TermsPage} />
              <Route path="/privacy-policy/" exact component={PrivacyPage} />
              <Route path="/mypage/:tabId?" component={MyPage} />
              <Route path="/trello" exact component={HomePage} />
              <Route path="/trello/:boardId/:menuId?/:tabId?" component={TrelloPage} />
            </Switch>
          </BasePage>
        </Route>
      </Switch>
    )
  }
}

export default Routes;