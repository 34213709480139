import { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { 
 } from "@blueprintjs/core";
import { 
  Bar
} from "react-chartjs-2";

import { withTranslation } from "react-i18next";
import i18n from "../../../../utils/i18n/i18n";

import { AppStore } from "../../../../stores/AppStore";


const Wrapper = styled.div``;
const ChartDiv = styled.div`
  width: 400px;
  border: 1px solid lightgray;
`;

interface ActionByTypeProps {}

interface InjectedProps extends ActionByTypeProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ActionByType extends Component<ActionByTypeProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    const data = JSON.parse(this.trelloStore.highlightsData);

    return (
      <Wrapper>
        <ChartDiv>
          <Bar 
            width={2}
            height={1}

            data={{
              labels: data.action_by_type.labels,
              datasets: [
                {
                  label: i18n.t("highlights-chart-current"),
                  data: data.action_by_type.current,
                  borderWidth: 1,
                  borderColor: '#39a2db',
                  backgroundColor: '#39a2db44',
                },
                {
                  label: i18n.t("highlights-chart-previous"),
                  data: data.action_by_type.previous,
                  borderWidth: 1,
                  borderColor: '#e8f0f2',
                  backgroundColor: '#e8f0f2dd',
                },
              ]
            }}
            options={{
              title: {
                display: true,
                text: i18n.t("highlights-chart-actionbytype")
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                  },
                }],
              },
              legend: { 
                position: 'left', 
              },
            }}
          />

        </ChartDiv>
      </Wrapper>
    )
  }
}

export default withTranslation()(ActionByType);