import { FC } from "react";

import styled from "styled-components/macro";
import { Icon } from "@blueprintjs/core";

import moment from "moment";

import { Card } from "../../../stores/models/TrelloBoard";
import { AppStore } from "../../../stores/AppStore";

const CardTag = styled.div`
  font-size: 8px;
  margin: 2px;
`;
const CardTagText = styled.span`
`;
const CardTagTextWithBG = styled.span`
  background: #a31542;
  color: white;
`;

const ChecklistDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
`;
const CheckitemDiv = styled.div`
  font-size: 8px;
  padding-left: 5px;
`;

interface CardProps {
  card: Card;
  appStore: AppStore;
}
interface CardTagProps extends CardProps {
  showTitle: boolean;
}
export const CardTagLastActivity: FC<CardTagProps> = (props) => {
  const { card, showTitle } = props;
  const { trelloStore } = props.appStore;

  if(card.date_last_activity) {
    return (
      <CardTag>
        <Icon icon="hand" iconSize={12} />&nbsp;
        {showTitle ? ' Last Activity:' : ''}&nbsp;
        <CardTagText>{moment(trelloStore.targetDate).diff(moment(card.date_last_activity), 'days')} days ago</CardTagText>
      </CardTag>                
    )
  } else {
    return null;
  }
} 

export const CardTagDuedate: FC<CardTagProps> = (props) => {
  const { card, showTitle } = props;
  const { trelloStore } = props.appStore;
  
  if(card.due) {
    return (
      <CardTag>
        <Icon icon="calendar" iconSize={12} />&nbsp;
        {showTitle ? ' Due Date:' : ''}&nbsp;
        {(card.due_complete || moment(card.due).isAfter(moment(trelloStore.targetDate), 'days')) ? (
          <CardTagText>{moment(card.due).format("YYYY-MM-DD")}</CardTagText>
        ) : (
          <CardTagTextWithBG>{moment(card.due).format("YYYY-MM-DD")}</CardTagTextWithBG>
        )}
      </CardTag>
    );
  } else {
    return null;
  }
}

export const CardTagMembers: FC<CardTagProps> = (props) => {
  const { card, showTitle } = props;
  const { trelloStore } = props.appStore;

  // 카드의 member 를 id 에서 name 변환하여 보여준다
  let members = card.id_members.map(card_member_id => {
    const model_member = trelloStore.selectableMembers.find(x => x.member_id === card_member_id)
    return model_member ? model_member.fullname : '';
  })

  if(members && members.length > 0) {
    return (
      <CardTag>
        <Icon icon="user" iconSize={12} />&nbsp;
        {showTitle ? ' Members on Card:' : ''}&nbsp;
        <CardTagText>{members.join(', ')}</CardTagText>
      </CardTag>                
    )
  } else {
    return null;
  }
}

export const CardTagLabels: FC<CardTagProps> = (props) => {
  const { card, showTitle } = props;
  const { trelloStore } = props.appStore;

  // 카드의 label 을 id 에서 name 으로 변환하여 보여준다
  let labels = card.id_labels.map(card_label_id => {
    const model_label = trelloStore.selectableLabels.find(x => x.label_id === card_label_id)
    return model_label ? model_label.name : '';
  });

  if(labels && labels.length > 0) {
    return (
      <CardTag>
        <Icon icon="label" iconSize={12} />&nbsp;
        {showTitle ? ' Labels on Card:' : ''}&nbsp;
        <CardTagText>{labels.join(', ')}</CardTagText>
      </CardTag>                
    )
  } else {
    return null;
  }
}

export const CardTagComments: FC<CardTagProps> = (props) => {
  const { card, showTitle } = props;

  if(card.num_comments > 0) {
    return (
      <CardTag>
        <Icon icon="comment" iconSize={12} />&nbsp;
        {showTitle ? ' # of Comments:' : ''}&nbsp;
        <CardTagText>{card.num_comments} comments</CardTagText>
      </CardTag>                
    )
  } else {
    return null;
  }
}

export const CardTagCheckitems: FC<CardTagProps> = (props) => {
  const { card, showTitle } = props;
  const { trelloStore } = props.appStore;

  // 카드의 체크아이템 현황을 완료/전체 숫자 형태로 보여준다
  let num_checkitems_all = 0;
  let num_checkitems_completed = 0;
  card.id_checklists.map(id_checklist => {
    const checklist = trelloStore.checklists.find(x => x.checklist_id === id_checklist);
    if(checklist) {
      checklist.checkitems.map(checkitem => {
        num_checkitems_all += 1;
        if(checkitem.state === 'complete') {
          num_checkitems_completed += 1
        }
        return null;
      })
    }
    return null;
  })
  let checkitems = num_checkitems_all > 0 ? `${num_checkitems_completed} / ${num_checkitems_all}` : '';

  if(checkitems && checkitems.length > 0) {
    return (
      <CardTag>
        <Icon icon="tick" iconSize={12} />&nbsp;
        {showTitle ? ' Checkitems:' : ''}&nbsp;
        <CardTagText>{checkitems}</CardTagText>
      </CardTag>                
    )
  } else {
    return null;
  }
}

export const CardCheckitems: FC<CardProps> = (props) => {
  const { card } = props;
  const { trelloStore } = props.appStore;

  if(card.id_checklists && card.id_checklists.length > 0) {
    return (
      <div>
        {card.id_checklists.map(id_checklist => {
          const checklist = trelloStore.checklists.find(x => x.checklist_id === id_checklist);
          return checklist ? (
            <div key={checklist.checklist_id}>
              <ChecklistDiv>{checklist.name}</ChecklistDiv>
              {checklist.checkitems.map(checkitem => {
                return (
                  <CheckitemDiv key={checkitem.checkitem_id}>
                    <Icon icon={checkitem.state === 'complete' ? "tick-circle" : "circle"} iconSize={12} />&nbsp;{checkitem.name}
                  </CheckitemDiv>
                );
              })}
            </div>
          ) : null;
        })}
      </div>
    );
  } else {
    return null;
  }
}
