import { Component } from "react";
import { RouteComponentProps } from "react-router";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { Toaster, Intent, Dialog, Position, Classes, Button } from "@blueprintjs/core";

import { AppStore } from "../../stores/AppStore";

import SideMenu from "./SideMenu";
import OptionsMenu from "./OptionsMenu";
import HighlightsPanel from "./panels/HighlightsPanel";
import BoardPanel from "./panels/BoardPanel";
import ActionsPanel from "./panels/ActionsPanel";
import ProgressPanel from "./panels/ProgressPanel";
import WorkflowPanel from "./panels/WorkflowPanel";
import SettingsPanel from "./panels/SettingsPanel";
import InfoMessage from "../../utils/InfoMessage";

import TargetDateInput from "./components/TargetDateInput";
import i18n from "../../utils/i18n/i18n";


const Wrapper = styled.div`
  display: flex;
`;
const Content = styled.div`
  padding: 0px 40px 0px 40px;
  width: calc(100% - 205px - 240px);
`;

interface PageParams {
  boardId: string;
  menuId: string;
}

interface TrelloPageProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends TrelloPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class TrelloPage extends Component<TrelloPageProps> {
  @observable timerId: number = 0;
  @action setTimerId(value: any) { this.timerId = value; }

  @observable isCollectDialogOpen = false;
  @action setIsCollectDialogOpen(value: boolean) { this.isCollectDialogOpen = value; }

  // 리스트, 카드 관련 로그가 하나도 없을 경우 계속해서 수집 다이얼로그가 나오는 현상 제어
  @observable isCollectAlready = false;
  @action setIsCollectAlready(value: boolean) { this.isCollectAlready = value; }

  constructor(props: TrelloPageProps) {
    super(props);
    makeObservable(this);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  async componentDidMount() {
    const { boardId } = this.props.match.params;

    try {
      // 로그인하지 않으면 접근할 수 없음
      if(!this.userStore.isLoggedIn) {
        alert('Not logged in')
        this.props.history.push('/');
        return;
      }
  
      // 새로고침해서 들어온 경우 로딩 필요
      if(!this.trelloStore.selectedBoardId) {
        await this.trelloStore.fetchSelectableBoards();
      }
  
      // 접근 가능한 보드가 아닐 경우 처리
      const selectedBoard = this.trelloStore.selectableBoards.find(x => x.board_id === boardId);
      if(!selectedBoard) {
        alert('Invalid board')
        this.props.history.push('/');
        return;
      } else {
        this.trelloStore.setSelectedBoardId(boardId);

        // 새로고침으로 기존 분석 결과가 없을 경우 analyze를 호출해야 함
        if(!this.trelloStore.isBusy && !this.trelloStore.isAnalyzed) {
          await this.trelloStore.analyzeTrelloData();
        }

        // 비동기 작업을 모니터링하는 타이머를 등록한다
        this.setTimerId(setInterval(() => this.checkBusy(), 1000));
      }
    } catch(e) {
      alert(`${e.name}: ${e.message}`);
      this.trelloStore.setSelectedBoardId('');
      this.props.history.push('/');
      return;
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  checkBusy = async () => {
    if(this.trelloStore.isBusy && !this.trelloStore.isBusyChecking) {
      try {
        this.trelloStore.setIsBusyChecking(true);

        // 데이터 수집 작업 체크
        if(this.trelloStore.taskType === this.trelloStore.CONST_TASK_TYPE_COLLECT) {
          let status = await this.trelloStore.collectTrelloDataStatus();
          if(status === 'SUCCESS' || status === 'FAILURE') {
            if(status === 'SUCCESS') {
              // 수집한 데이터 대상으로 다시 분석을 수행
              this.trelloStore.setSelectedBoardId(this.trelloStore.selectedBoardId);
              await this.trelloStore.analyzeTrelloData();

              // 보드 업데이트 날짜를 반영
              await this.trelloStore.fetchSelectableBoards();
            }
            Toaster.create({position: Position.BOTTOM}).show({ 
              message: status, 
              intent: status === 'SUCCESS' ? Intent.SUCCESS : Intent.DANGER,
            })
          }
        }

        // 데이터 분석 작업 체크
        if(this.trelloStore.taskType === this.trelloStore.CONST_TASK_TYPE_ANALYZE) {
          let status = await this.trelloStore.analyzeTrelloDataStatus();
          if(status === 'SUCCESS' || status === 'FAILURE') {
            Toaster.create({position: Position.BOTTOM}).show({ 
              message: status, 
              intent: status === 'SUCCESS' ? Intent.SUCCESS : Intent.DANGER 
            })
            if(status === 'SUCCESS') {
              if(
                this.trelloStore.lists.length === 1 && 
                this.trelloStore.lists[0].list_id === this.trelloStore.CONST_ID_LIST_DELETED && 
                this.trelloStore.cards.length === 0
              ) {
                // 데이터 수집이 안된 보드는 데이터 수집 알림을 표시
                this.setIsCollectDialogOpen(true);
              } else {
                // 데이터가 있는 경우에는 ticket 유효 기간을 체크
                if(this.trelloStore.isSelectedBoardExpired) {
                  Toaster.create({position: Position.BOTTOM}).show({ 
                    message: i18n.t("settings-ticket-notickets"), 
                    intent: Intent.WARNING 
                  })
                  this.props.history.push(`/trello/${this.trelloStore.selectedBoardId}/settings/ticket`)
                }
              }
            }
            if(status === 'FAILURE') {
              this.trelloStore.setSelectedBoardId('');
              this.props.history.push('/');
            }
          }
        }

        this.trelloStore.setIsBusyChecking(false);
      } catch (e) {
        alert(`${e.name}: ${e.message}`);
        this.trelloStore.setSelectedBoardId('');
        this.props.history.push('/');
      }
    }
  }

  handleCollectNowClicked = async () => {
    try {
      this.setIsCollectDialogOpen(false);
      await this.trelloStore.collectTrelloData();
      this.setIsCollectAlready(true);
    } catch (e) {
      Toaster.create({position: Position.BOTTOM}).show({ 
        message: `${e.name}: ${e.message}`, 
        intent: Intent.DANGER 
      })
    }
  }

  handleBackToBoardClicked = () => {
    this.setIsCollectDialogOpen(false);
    this.setIsCollectAlready(false);
    this.props.history.replace("/");
  }

  render() {
    // 새로고침한 경우 analyze 를 실행할 수 있도록 잠시 기다려야함
    if(!this.trelloStore.isBusy && !this.trelloStore.isAnalyzed) {
      return (<></>);
    }    

    const {menuId} = this.props.match.params;

    return (
      <Wrapper>
        <SideMenu />
        <Content>
        {this.trelloStore.isBusy ? (
          <InfoMessage 
            title={'⏳'}
            text={
              i18n.t('etc-working-wait') + 
              (this.trelloStore.taskSeconds > 0 ? '('+this.trelloStore.taskSeconds+')' : '')}
          />
        ) : (
          <>
            <TargetDateInput />
            {(menuId === 'highlights' || !menuId) && (<HighlightsPanel />)}
            {menuId === 'board' && (<BoardPanel />)}
            {menuId === 'actions' && (<ActionsPanel />)}
            {menuId === 'progress' && (<ProgressPanel />)}
            {menuId === 'workflow' && (<WorkflowPanel />)}
            {menuId === 'settings' && (<SettingsPanel />)}
          </>
        ) }          
        </Content>
        <OptionsMenu />
        <Dialog 
          title={i18n.t("popup-nodata-title")}
          canEscapeKeyClose={false}
          canOutsideClickClose={false}
          isCloseButtonShown={false}
          isOpen={this.isCollectDialogOpen}
        >
          <div className={Classes.DIALOG_BODY}>
            {this.isCollectAlready ? (
              <p>{i18n.t("popup-nodata-msg-nodata")}</p>
            ) : (
              <p>{i18n.t("popup-nodata-msg-nocollect")}</p>
            )}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              {!this.isCollectAlready && (
                <Button 
                  intent={Intent.PRIMARY} 
                  text={i18n.t("popup-nodata-btn-collect")} 
                  onClick={() => {this.handleCollectNowClicked()}}
                />
              )}
              <Button 
                intent={Intent.DANGER} 
                text={i18n.t("popup-nodata-btn-back")}  
                onClick={() => {this.handleBackToBoardClicked()}}
              />
            </div>
          </div>
        </Dialog>
      </Wrapper>
    )
  }
}

export default TrelloPage;