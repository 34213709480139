import { Component } from "react";

import { inject, observer } from "mobx-react";

import { RouteComponentProps, withRouter } from "react-router";

import styled from "styled-components/macro";
import { 
  Tab, Tabs,
 } from "@blueprintjs/core";

import WorkflowGraphTab from "../tabs/WorkflowGraphTab";
import WorkflowListTab from "../tabs/WorkflowListTab";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;

interface PageParams {
  boardId: string;
  tabId: string;
}
interface WorkflowPanelProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends WorkflowPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class WorkflowPanel extends Component<WorkflowPanelProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    const { boardId, tabId } = this.props.match.params;

    return (
      <Wrapper>
        <Tabs
          id="tabs-workflow"
          onChange={(newTabId) => {this.props.history.push(`/trello/${boardId}/workflow/${newTabId}`)}}
          selectedTabId={tabId ? tabId : 'list'}
        >
          <Tab id="list" title={i18n.t("workflow-tabtitle-list")} panel={(<WorkflowListTab />)} />
          <Tab id="graph" title={i18n.t("workflow-tabtitle-graph")} panel={(<WorkflowGraphTab />)} />
        </Tabs>
      </Wrapper>
    )
  }
}

export default withTranslation()(withRouter(WorkflowPanel));