import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import { RouteComponentProps, withRouter } from "react-router";

import moment from "moment";

import styled from "styled-components/macro";
import { 
  Collapse,
} from "@blueprintjs/core";
import SelectedCardList from "../components/SelectedCardList";
import ActionByDate from "../components/highlights/ActionByDate";
import ActionByMember from "../components/highlights/ActionByMember";
import ActionByType from "../components/highlights/ActionByType";
import PerformanceCardStatus from "../components/highlights/PerformanceCardStatus";
import PerformanceCheckItems from "../components/highlights/PerformanceCheckItems";
import CardsDelayedByMember from "../components/highlights/CardsDelayedByMember";
import CardsDelayedByLabel from "../components/highlights/CardsDelayedByLabel";
import CardsWIPByMember from "../components/highlights/CardsWIPByMember";
import CardsWIPByLabel from "../components/highlights/CardsWIPByLabel";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div`
  overflow: scroll;
`;
const Remark = styled.span`
  background-color: #fcf8e3;
  font-weight: bold;
`;
const RemarkableLink = styled(Remark)`
  background-color: #f58634;
  color: white;
  cursor: pointer;
`;
const CollapsibleDiv = styled.div`
  margin-top: 20px;
  width: 800px;
`;
const MainContent = styled.div`
  margin: 10px;
  border: 1px solid rgb(238, 238, 238);
  box-shadow: rgb(0 0 0 / 8%) 0px 10px 10px 0px;
  padding: 70px 70px 120px;
`;
const MainTitle = styled.div`
  font-size: 2rem;
  font-weight: bold;
`;
const MainFooter = styled.div`
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
`;
const Divider = styled.div`
  height: 1px;
  border: 1px dashed lightgray;
  margin-bottom: 40px;
`;
const Section = styled.div`
  display: float;
  margin-bottom: 40px;
`;
const SectionTitle = styled.div`
  min-width: 200px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: gray;
  // color: rgb(127, 131, 144);
`;
const SectionContent = styled.div`
`;
const SectionContentText = styled.div`
  font-size: 1rem;
`;

interface PageParams {
  boardId: string;
}
interface HighlightsTabProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends HighlightsTabProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class HighlightsTab extends Component<HighlightsTabProps> {
  @observable showChanged = false;
  @action setShowChanged(value: boolean) { this.showChanged = value; }
  @observable showNewlyDone = false;
  @action setShowNewlyDone(value: boolean) { this.showNewlyDone = value; }
  @observable showCheckItem = false;
  @action setShowCheckItem(value: boolean) { this.showCheckItem = value; }
  @observable showWIP = false;
  @action setShowWIP(value: boolean) { this.showWIP = value; }
  @observable showDelayed = false;
  @action setShowDelayed(value: boolean) { this.showDelayed = value; }

  constructor(props: HighlightsTabProps) {
    super(props);
    makeObservable(this);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  renderTable = (filter: string) => {
    return (
      <CollapsibleDiv>
        <SelectedCardList 
          isSelected={(card) => {
            return (card.filter.indexOf(filter) > -1);
          }}
        />
      </CollapsibleDiv>
    );
  }

  render() {
    const data = JSON.parse(this.trelloStore.highlightsData);
    const date_to = moment(this.trelloStore.targetDate)
    const date_from = moment(date_to).subtract(7, 'days')

    return (
      <Wrapper>
        <MainContent>
          <Section>
            <MainTitle>{this.trelloStore.selectedBoard.board_name} <small>({date_from.format("YYYY-MM-DD")} ~ {date_to.format("YYYY-MM-DD")})</small></MainTitle>
          </Section>
          <Divider />
          <Section>
            <SectionTitle>{i18n.t("highlights-activities-title")}</SectionTitle>
            <SectionContent>
              <SectionContentText>
                <Remark>{i18n.t("highlights-activities-desc")}</Remark>: {data.num_weekly_actions}
              </SectionContentText>
              <div style={{height:'20px'}}></div>
              <ActionByDate />
              <div style={{display:'flex'}}>
                <ActionByMember />
                <ActionByType />
              </div>
            </SectionContent>
          </Section>
          <Divider />
          <Section>
            <SectionTitle>{i18n.t("highlights-changes-title")}</SectionTitle>
            <SectionContent>
              <SectionContentText>
              <Remark>{i18n.t("highlights-changes-desc")}</Remark>: &nbsp;
                <RemarkableLink onClick={() => {this.setShowChanged(!this.showChanged)}}>{data.num_of_changed_cards}</RemarkableLink>
              </SectionContentText>
              <Collapse isOpen={this.showChanged}>
                {this.renderTable('changed')}
              </Collapse>
            </SectionContent>
          </Section>
          <Divider />
          <Section>
            <SectionTitle>{i18n.t("highlights-achievements-title")}</SectionTitle>
            <SectionContent>
              <SectionContentText style={{marginBottom:'10px'}}>
                <Remark>{i18n.t("highlights-achievements-desc-checkitem")}</Remark>: {data.checkitem_completed.sum_of_completed} &nbsp;
                <RemarkableLink onClick={() => {this.setShowCheckItem(!this.showCheckItem)}}>&lt;{i18n.t("highlights-achievements-desc-checkitem-card")}&gt;</RemarkableLink>
              </SectionContentText>
              <Collapse isOpen={this.showCheckItem}>
                {this.renderTable('checkitem')}
              </Collapse>
              <div style={{height:'10px'}}></div>
              <PerformanceCheckItems />

              <div style={{height:'40px'}}></div>

              <SectionContentText style={{marginBottom:'10px'}}>
                <Remark>{i18n.t("highlights-achievements-desc-card")}</Remark>: &nbsp;
                <RemarkableLink onClick={() => {this.setShowNewlyDone(!this.showNewlyDone)}}>
                  {data.card_status.num_of_newly_done_cards}
                </RemarkableLink> 
              </SectionContentText>
              <Collapse isOpen={this.showNewlyDone}>
                {this.renderTable('newly_done')}
              </Collapse>
              <div style={{height:'10px'}}></div>
              <PerformanceCardStatus />
            </SectionContent>
          </Section>
          <Divider />
          <Section>
            <SectionTitle>{i18n.t("highlights-progress-title")}</SectionTitle>
            <SectionContent>
              <SectionContentText style={{marginBottom:'10px'}}>
                <Remark>{i18n.t("highlights-progress-desc-card")}</Remark>: &nbsp;
                <RemarkableLink onClick={() => {this.setShowWIP(!this.showWIP)}}>
                  {data.num_of_cards_in_progress}
                </RemarkableLink> 
              </SectionContentText>
              <Collapse isOpen={this.showWIP}>
                {this.renderTable('wip')}
              </Collapse>
              <div style={{height:'10px'}}></div>
              <div style={{display:'flex'}}>
                <CardsWIPByMember />
                <CardsWIPByLabel />
              </div>

              <div style={{margin:'40px 0'}} />

              <SectionContentText style={{marginBottom:'10px'}}>
                <Remark>{i18n.t("highlights-progress-desc-duedate")}</Remark>: &nbsp;
                <RemarkableLink onClick={() => {this.setShowDelayed(!this.showDelayed)}}>
                  {data.num_of_delayed_cards}
                </RemarkableLink> 
              </SectionContentText>
              <Collapse isOpen={this.showDelayed}>
                {this.renderTable('delayed')}
              </Collapse>
              <div style={{height:'10px'}}></div>
              <div style={{display:'flex'}}>
                <CardsDelayedByMember />
                <CardsDelayedByLabel />
              </div>

            </SectionContent>
          </Section>
          <Divider />
          <MainFooter>👏👏👏 &nbsp; {i18n.t("highlights-message")} &nbsp; 👏👏👏</MainFooter>
        </MainContent>
      </Wrapper>
    )
  }
}

export default withTranslation()(withRouter(HighlightsTab));