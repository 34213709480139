import { types, flow } from "mobx-state-tree";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { PREF_LANGUAGE_KEY } from "../utils/i18n/i18n";

const AUTH_TOKEN_KEY = "kanbantm_authtoken";

export const UserStoreModel = types
  .model("UserStore", {
    isLoggedIn: types.optional(types.boolean, false),
    username: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
  })
  .views(self => ({
    get authToken() {
      return window.localStorage.getItem(AUTH_TOKEN_KEY);
    },
  }))
  .actions(self => ({
    setUserInfo({
      username,
      email,
    }: {
      username: string;
      email: string;
    }) {
      self.username = username;
      self.email = email;
    }
  }))
  .actions(self => {
    const checkLoginStatus = () => {
      if (self.authToken) {
        self.isLoggedIn = true;
        axios.defaults.headers.common["Authorization"] = `Bearer ${self.authToken}`;

        const decoded: any = jwtDecode(self.authToken);
        self.setUserInfo({
          username: decoded.username,
          email: decoded.email,
        });
      } else {
        self.isLoggedIn = false;
        axios.defaults.headers.common["Authorization"] = null;

        self.setUserInfo({
          username: "",
          email: "",
        });     
      }
    };

    const login = flow(function*(token:string) {
      try {

        const { data }: { data: any } = yield axios.create().post(
          `/trello/login/`, {
            token: token
          }
        );

        window.localStorage.setItem(AUTH_TOKEN_KEY, data.token);
        checkLoginStatus();
      } catch (e) {
        console.log("login error", e);
        throw e;
      }
    });

    const logout = () => {
      window.localStorage.removeItem(AUTH_TOKEN_KEY);
      checkLoginStatus();
    };

    const setLanguage = (lang: string) => {
      window.localStorage.setItem(PREF_LANGUAGE_KEY, lang);
    }

    return {
      login,
      logout,
      checkLoginStatus,
      setLanguage,
    };    
  })

type UserStoreModelType = typeof UserStoreModel.Type;
export interface UserStore extends UserStoreModelType {}
