import React, { Component, RefObject } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { Button, Intent } from "@blueprintjs/core";
import { Line } from "react-chartjs-2";

import ListClassPopup from "../components/ListClassPopup";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";

import * as Constants from "../../../utils/Constants";


const Wrapper = styled.div``;
const Note = styled.div`
  line-height: 30px;
  margin-bottom: 1rem;
`;
const ChartDiv = styled.div``;


interface ProgressTabProps {}

interface InjectedProps extends ProgressTabProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ProgressTab extends Component<ProgressTabProps> {
  private chartRef:RefObject<Line>;
  private labels:Array<string> = [];
  private datasets:Array<any> = [];

  @observable readyToShow: boolean = false;
  @action setReadyToShow(value: boolean) { this.readyToShow = value; }
  @observable isListClassPopupOpen = false;
  @action setIsListClassPopupOpen(value: boolean) { this.isListClassPopupOpen = value; }

  @observable changeListId: string = "";
  @action setChangeListId(value: string) { this.changeListId = value; }
  @observable changeListColor: string = "";
  @action setChangeListColor(value: string) { this.changeListColor = value; }

  @observable yMax = 0;
  @action setYMax(value: number) { this.yMax = value; }
  private yAcc: number = 0;
  private ySuggest: number = 0;

  constructor(props: ProgressTabProps) {
    super(props);
    this.chartRef = React.createRef();
    makeObservable(this);
  }

  componentDidMount() {
    const data = JSON.parse(this.trelloStore.progressFlowData);

    this.labels = data.labels.slice();
    this.datasets = data.datasets.slice();

    this.setReadyToShow(true);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  handleWheel = (e: any) => {
    this.yAcc += e.deltaY;
    if(this.yAcc >= 50) {
      this.setYMax(this.yMax+5);
      this.yAcc = 0;
    }
    if(this.yAcc <= -50) {
      if(this.yMax > this.ySuggest) {
        this.setYMax(this.yMax-5);
      }
      this.yAcc = 0;
    }
  }

  // 휠 이벤트가 다른 영역 스크롤을 일으키지 않도록 조정
  passivePreventDefault = (e: any) => {
    if (e.preventDefault) { e.preventDefault(); }
  }
  handleMouseEnter = (e: any) => {
    window.addEventListener('wheel', this.passivePreventDefault, {passive: false});
  }
  handleMouseLeave = (e: any) => {
    window.removeEventListener('wheel', this.passivePreventDefault);
  }

  handleListClassChanged = (list_id: string, class_before: string, class_after: string) => {
    if(list_id && class_after) {
      // 컬러를 수정하면 차트에 반영한다
      this.chartRef.current?.props.data.datasets.forEach((dataset: any) => {
        if(list_id === dataset.id) {
          dataset.backgroundColor = Constants.CFD_LIST_COLOR.get(class_after);
        }
      });
      this.chartRef.current?.forceUpdate();

      // 시소 멤버가 변경한 값은 로그에 남겨서 나중에 정답셋으로 사용한다
      if(this.userStore.email.endsWith('@seeso.kr')) {
        try {
          this.trelloStore.classifyList(list_id, class_before, class_after);
        } catch (e) {
          alert(`${e.name}: ${e.message}`);
        }
      }    
    }   
  }

  render() {
    return (
      <Wrapper>
        <Note>
          {i18n.t("progress-progress-desc")}
          &nbsp;
          <Button 
            minimal
            text={i18n.t("progress-progress-click")}
            intent={Intent.PRIMARY}
            onClick={() => {this.setIsListClassPopupOpen(true)}}
          /> 
        </Note>
        {this.readyToShow && (
          <ChartDiv
            onWheel={this.handleWheel}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}            
          >
            <Line 
              ref={this.chartRef}
              data={{
                labels: this.labels ? this.labels : [],
                datasets: this.datasets ? this.datasets : []
              }}
              options={{
                scales: { 
                  xAxes: [{
                    type: "time",
                    time: {
                      unit: 'day',
                      unitStepSize: 7,
                      tooltipFormat: "YYYY-MM-DD",
                      displayFormats: {
                        day: 'YYYY-MM-DD'
                      }
                    },
                    gridLines: {
                      color: 'black',
                      borderDash: [20, 2],
                      z: 999,
                    }
                  }],
                  yAxes: [{ 
                    stacked: true,
                    ticks: {
                      suggestedMax: this.yMax,
                      callback: (value: number, index: any, values: any) => {
                        if(this.yMax === 0) {
                          this.ySuggest = value;
                          this.setYMax(value);
                        }
                        return value;
                      },
                    }
                  }] 
                },
                legend: { position: 'left', align: 'start', reverse: true },
              }}
            />
          </ChartDiv>
        )}
        <ListClassPopup 
          datasets={this.datasets.slice().reverse()}  // legend 를 reverse 했기 때문에 동일하게 맞춤
          isOpen={this.isListClassPopupOpen}
          onClose={() => {this.setIsListClassPopupOpen(false)}}
          onClassChanged={(list_id: string, class_before: string, class_after: string) => {this.handleListClassChanged(list_id, class_before, class_after)}}
        />           
      </Wrapper>
    )
  }
}

export default withTranslation()(ProgressTab);