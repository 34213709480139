import { Component } from "react";

import { inject, observer } from "mobx-react";

import { RouteComponentProps, withRouter } from "react-router";

import styled from "styled-components/macro";
import { 
  Tab, Tabs,
 } from "@blueprintjs/core";

import SettingsTicketTab from "../tabs/SettingsTicketTab";
import SettingsGeneralTab from "../tabs/SettingsGeneralTab";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;


interface PageParams {
  boardId: string;
  tabId: string;
}
interface SettingsPanelProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends SettingsPanelProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SettingsPanel extends Component<SettingsPanelProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  render() {
    const { boardId, tabId } = this.props.match.params;

    return (
      <Wrapper>
        <Tabs
          id="tabs-settings"
          onChange={(newTabId) => {this.props.history.push(`/trello/${boardId}/settings/${newTabId}`)}}
          selectedTabId={tabId ? tabId : ''}
        >
          <Tab id="" title={i18n.t("settings-tabtitle-general")} panel={(<SettingsGeneralTab />)} />
          <Tab id="ticket" title={i18n.t("settings-tabtitle-ticket")} panel={(<SettingsTicketTab />)} />
        </Tabs>        
      </Wrapper>
    )
  }
}

export default withTranslation()(withRouter(SettingsPanel));