import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import { RouteComponentProps, withRouter } from "react-router";

import styled from "styled-components/macro";
import CardDetailPopup from "../components/CardDetailPopup";
import { 
  CardTagDuedate, CardTagMembers, CardTagLabels, CardTagLastActivity, CardTagComments, CardTagCheckitems
} from "../components/CardDetailComponents";

import { AppStore } from "../../../stores/AppStore";
import { Card } from "../../../stores/models/TrelloBoard";


const Wrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  height: calc(100vh - 180px);
  padding: 10px;
  border: 1px solid lightgrey;
`;
const List = styled.div`
  width: 200px;
  border: 1px solid lightgrey;
  margin: 0px 10px;
  flex: 0 0 auto;
  background: #f8f8f8;
`;
const ListHeader = styled.div`
  border-bottom: 1px solid lightgrey;
  padding: 10px;

  :hover {
    cursor: pointer;
    background: lightgrey;
  }
`;
const ListContent = styled.div`
  padding: 10px;
  overflow-y: scroll;
  max-height: calc(100vh - 230px);
`;
const CardDiv = styled.div`
  background: white;
  padding: 5px;
  border: 1px solid #eeeeee;
  border-radius: 5px;

  :hover {
    cursor: pointer;
    background: lightgrey;
  }
`;
const CardBody = styled.div`
  padding-bottom: 5px;
  overflow: hidden;
`;
const CardFooter = styled.div``;

interface PageParams {
}
interface BoardCurrentTabProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends BoardCurrentTabProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class BoardCurrentTab extends Component<BoardCurrentTabProps> {
  @observable isCardDetailPopupOpen = false;
  @action setIsCardDetailPopupOpen(value: boolean) { this.isCardDetailPopupOpen = value; }
  @observable selectedCardId = "";
  @action setSelectedCardId(value: string) { this.selectedCardId = value; }

  constructor(props: BoardCurrentTabProps) {
    super(props);
    makeObservable(this);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  handleCardSelected = (cardId: string) => {
    this.setSelectedCardId(cardId);
    this.setIsCardDetailPopupOpen(true);
  }

  isFiltered = (card: any) => {
    const query = new URLSearchParams(this.props.location.search);
    const filter = query.get('filter');

    // 필터가 있는데 조건에 안맞으면 true 리턴
    return (filter && card.filter.indexOf(filter) === -1);
  }

  renderCard(card: Card) {
    return (
      <CardDiv key={card.card_id} onClick={() => {this.handleCardSelected(card.card_id)}}>
        <CardBody>{card.name}</CardBody>
        <CardFooter>
          <CardTagLastActivity card={card} showTitle={false} appStore={this.injected.appStore} />
          <CardTagDuedate card={card} showTitle={false} appStore={this.injected.appStore} />
          <CardTagMembers card={card} showTitle={false} appStore={this.injected.appStore} />
          <CardTagLabels card={card} showTitle={false} appStore={this.injected.appStore} />
          <CardTagComments card={card} showTitle={false} appStore={this.injected.appStore} />
          <CardTagCheckitems card={card} showTitle={false} appStore={this.injected.appStore} />
        </CardFooter>
      </CardDiv>
    )
  }

  render() {
    return (
      <Wrapper>
        {this.trelloStore.lists.map(list_data => {
          return (
            <div key={list_data.list_id}>
            <List>
              <ListHeader onClick={() => {this.handleCardSelected(list_data.list_id)}}>{list_data.name} ({this.trelloStore.cards.filter(card => card.id_list === list_data.list_id).length})</ListHeader>
              <ListContent>
                {
                  // 리스트 내 카드를 필터링하여 보여준다
                  this.trelloStore.cards.filter(card => card.id_list === list_data.list_id).map(
                    card => {
                      return this.isFiltered(card) ? null : this.renderCard(card);
                    }
                  )
                } 
              </ListContent>
            </List>
            </div>
          );
        })}
        <CardDetailPopup 
          cardId={this.selectedCardId}
          isOpen={this.isCardDetailPopupOpen}
          onClose={() => {this.setIsCardDetailPopupOpen(false)}}
        />        
      </Wrapper>
    )
  }
}

export default withRouter(BoardCurrentTab);