
import { types, Instance } from "mobx-state-tree";
import { UserStoreModel } from "./UserStore";
import { TrelloStoreModel } from "./TrelloStore";

export const AppStoreModel = types.model("AppStore", {
  userStore: types.optional(UserStoreModel, {}),
  trelloStore: types.optional(TrelloStoreModel, {}),
});

export interface AppStore extends Instance<typeof AppStoreModel> {}