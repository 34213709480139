import { Component } from "react";

import { observable, makeObservable, action } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {
  HTMLTable,
} from "@blueprintjs/core";
import CardDetailPopup from "./CardDetailPopup";

import moment from "moment";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;


interface SelectedCardListProps {
  isSelected: (card: any) => boolean;
}

interface InjectedProps extends SelectedCardListProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class SelectedCardList extends Component<SelectedCardListProps> {
  @observable isPopupOpen = false;
  @action setIsPopupOpen(value: boolean) { this.isPopupOpen = value; }
  @observable selectedCardId = "";
  @action setSelectedCardId(value: string) { this.selectedCardId = value; }

  constructor(prop: SelectedCardListProps) {
    super(prop);
    makeObservable(this);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  handleCardSelected = (cardId: string | undefined) => {
    if(cardId) {
      this.setSelectedCardId(cardId);
      this.setIsPopupOpen(true);
    }
  }  

  render() {
    return (
      <Wrapper>
        <HTMLTable 
          condensed
          striped
          interactive
          style={{width:'100%'}}
        >
          <thead>
            <tr>
              <th>List</th>
              <th>Card</th>
              <th>Due</th>
              <th>Last activity</th>
            </tr>
          </thead>
          <tbody>
            {this.trelloStore.cards.map(card => {
              if(this.props.isSelected(card)) {
                const list = this.trelloStore.lists.find(x => x.list_id === card.id_list);
                return (
                  <tr key={card.card_id} onClick={() => {this.handleCardSelected(card.card_id)}}>
                    <td style={{maxWidth:'150px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>{list && list.name}</td>
                    <td style={{maxWidth:'430px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>{card.name}</td>
                    <td style={{minWidth:'110px'}}>{card.due && moment(card.due).format("YYYY-MM-DD")}</td>
                    <td style={{minWidth:'110px'}}>{moment(this.trelloStore.targetDate).diff(moment(card.date_last_activity), 'days')} days ago</td>
                  </tr>
                );
              } else {
                return null;
              }
            })}
          </tbody>
        </HTMLTable>
        <CardDetailPopup 
          cardId={this.selectedCardId}
          isOpen={this.isPopupOpen}
          onClose={() => {this.setIsPopupOpen(false)}}
        />    
      </Wrapper>
    )
  }
}

export default SelectedCardList;