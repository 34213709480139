import { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { 
  FocusStyleManager,
  FormGroup, Checkbox,
  Tooltip, Collapse,
  Icon,
} from "@blueprintjs/core";
import { DateInput, Classes, } from "@blueprintjs/datetime";

import moment from "moment";

import { AppStore } from "../../stores/AppStore";

import { withTranslation } from "react-i18next";
import i18n from "../../utils/i18n/i18n";

const Wrapper = styled.div`
  width: 240px;
  min-height: calc(100vh - 50px);
  padding: 0px 0px 40px 0px;
  border-left: 1px solid lightgray;
  background: #ffffff;
`;
const MenuTitle = styled.h3`
  margin: 20px 0;
  padding-left: 20px;
`;
const OptionsContentDiv = styled.div``;
const StartDateDiv = styled(OptionsContentDiv)`
  padding-left: 20px;
`;
const MembersDiv = styled(OptionsContentDiv)``;
const LabelsDiv = styled(OptionsContentDiv)``;
const ListsDiv = styled(OptionsContentDiv)``;
const CheckBoxRow = styled.div`
  padding-left: 30px;
`;
const FilterDiv = styled(FormGroup)`
  margin: 0px;
  & > label { margin-bottom: 0px !important }
`;
const FilterTitle = styled(FormGroup)`
  margin: 0;
  background-color: #f0f0f0;
  padding-left: 5px;
  border-top: 1px solid lightgray;

  & > label {
    margin-bottom: 0px;
  }
`;
const FilterTitleText = styled.span`
  cursor: pointer;
  line-height: 30px;

  & .bp3-popover-wrapper {
    margin-top: 0px !important;
  }
`;


interface OptionsMenuProps {}

interface InjectedProps extends OptionsMenuProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class OptionsMenu extends Component<OptionsMenuProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();
  }

  renderDay = (day: Date) => {
    const date = day.getDate();
    const has_data = this.trelloStore.selectableDates.find(x => moment(x).isSame(moment(day), 'days'))
    return (
      <div className={Classes.DATEPICKER_DAY_WRAPPER}>
        {has_data ? (<span style={{color:'red'}}>{date}</span>) : date}
      </div>
    );
  }

  render() {
    return (
      <Wrapper>
          <MenuTitle>{i18n.t("filter-maintitle")}</MenuTitle>
          {!this.trelloStore.isBusy && (
            <div style={{display:'flex', flexDirection:'column'}}>
              <StartDateDiv>
                <FormGroup label={
                  <Tooltip placement="left" content={<span>{i18n.t("filter-startdate-tooltip")}</span>}>
                    <div>{i18n.t("filter-startdate")}</div>
                  </Tooltip>
                }>
                  <DateInput
                    parseDate={(str) => new Date(str)}
                    formatDate={(date: Date) => moment(date).format("YYYY-MM-DD")}
                    placeholder="Start Date"
                    minDate={new Date(this.trelloStore.minDate)}
                    maxDate={new Date(this.trelloStore.targetDate)}          
                    value={new Date(this.trelloStore.startDate)}
                    onChange={(e) => { this.trelloStore.setStartDate(e); }}
                    dayPickerProps={{renderDay: this.renderDay}}
                  />
                </FormGroup>
              </StartDateDiv>
              <MembersDiv>
                <FilterDiv label={
                  <FilterTitle label={
                    <FilterTitleText
                      onClick={() => {this.trelloStore.setFilterActionBysOpened(!this.trelloStore.filterActionBysOpened)}}
                    >
                      <Icon icon={this.trelloStore.filterActionBysOpened ? "caret-down" : "caret-right"} />&nbsp;
                      <span style={{display:'inline-block'}}>
                        <Tooltip placement="left" content={<span>{i18n.t("filter-by-member-tooltip")}</span>}>
                          <div>{i18n.t("filter-by-member")}</div>
                        </Tooltip>
                      </span>
                    </FilterTitleText>
                  } inline>
                    <Checkbox 
                      indeterminate={
                        (0 < this.trelloStore.selectedActionByIds.length) && 
                        (this.trelloStore.selectedActionByIds.length < this.trelloStore.selectableActionBys.length)
                      }
                      checked={this.trelloStore.selectedActionByAll}
                      onChange={(e) => {this.trelloStore.changeSelectedActionByAll(e.currentTarget.checked)}}
                      style={{margin:'7px 0px'}}
                    />
                  </FilterTitle>
                }>
                  <Collapse isOpen={this.trelloStore.filterActionBysOpened}>
                  {this.trelloStore.selectableActionBys.map(x => {
                    return (
                      <CheckBoxRow key={x.member_id}>
                        <Tooltip
                          placement="left"
                          content={`${x.num_actions} actions on ${x.num_cards} cards`}
                        >
                          <Checkbox 
                            label={`${x.fullname} (${x.username})`} 
                            checked={this.trelloStore.selectedActionByIds.includes(x.member_id)}
                            onChange={(e) => { this.trelloStore.changeSelectedActionByIds(x.member_id); }}
                          />
                        </Tooltip>
                      </CheckBoxRow>
                    );
                  })}
                  </Collapse>
                </FilterDiv>
              </MembersDiv>              
              <MembersDiv>
                <FilterDiv label={
                  <FilterTitle label={
                    <FilterTitleText
                      onClick={() => {this.trelloStore.setFilterMembersOpened(!this.trelloStore.filterMembersOpened)}}
                    >
                      <Icon icon={this.trelloStore.filterMembersOpened ? "caret-down" : "caret-right"} />&nbsp;
                      <span style={{display:'inline-block'}}>
                        <Tooltip placement="left" content={<span>{i18n.t("filter-by-card-member-tooltip")}</span>}>
                          <div>{i18n.t("filter-by-card-member")}</div>
                        </Tooltip>
                      </span>
                    </FilterTitleText>                    
                  } inline>
                    <Checkbox 
                      indeterminate={
                        (0 < this.trelloStore.selectedMemberIds.length) && 
                        (this.trelloStore.selectedMemberIds.length < this.trelloStore.selectableMembers.length)
                      }
                      checked={this.trelloStore.selectedMemberAll}
                      onChange={(e) => {this.trelloStore.changeSelectedMemberAll(e.currentTarget.checked)}}
                      style={{margin:'7px 0px'}}
                    />
                  </FilterTitle>
                }>
                  <Collapse isOpen={this.trelloStore.filterMembersOpened}>
                  {this.trelloStore.selectableMembers.map(x => {
                    return (
                      <CheckBoxRow key={x.member_id}>
                        <Tooltip
                          placement="left"
                          content={`${x.num_actions} actions on ${x.num_cards} cards`}
                        >
                          <Checkbox 
                            label={x.member_id === 'none' ? 'none' : `${x.fullname} (${x.username})`} 
                            checked={this.trelloStore.selectedMemberIds.includes(x.member_id)}
                            onChange={(e) => { this.trelloStore.changeSelectedMemberIds(x.member_id); }}
                          />
                        </Tooltip>
                      </CheckBoxRow>
                    );
                  })}
                  </Collapse>
                </FilterDiv>
              </MembersDiv>
              <LabelsDiv>
              <FilterDiv label={
                <FilterTitle label={
                  <FilterTitleText
                    onClick={() => {this.trelloStore.setFilterLabelsOpened(!this.trelloStore.filterLabelsOpened)}}
                  >
                    <Icon icon={this.trelloStore.filterLabelsOpened ? "caret-down" : "caret-right"} />&nbsp;
                    <span style={{display:'inline-block'}}>
                        <Tooltip placement="left" content={<span>{i18n.t("filter-by-card-label-tooltip")}</span>}>
                          <div>{i18n.t("filter-by-card-label")}</div>
                        </Tooltip>
                      </span>
                  </FilterTitleText>                     
                } inline>
                  <Checkbox 
                    indeterminate={
                      (0 < this.trelloStore.selectedLabelIds.length) && 
                      (this.trelloStore.selectedLabelIds.length < this.trelloStore.selectableLabels.length)                    
                    } 
                    checked={this.trelloStore.selectedLabelAll}
                    onChange={(e) => {this.trelloStore.changeSelectedLabelAll(e.currentTarget.checked)}}
                    style={{margin:'7px 0px'}}
                  />
                </FilterTitle>
              }>
                <Collapse isOpen={this.trelloStore.filterLabelsOpened}>
                {this.trelloStore.selectableLabels.map(x => {
                  return (
                    <CheckBoxRow key={x.label_id}>
                      <Tooltip
                        placement="left"
                        content={`${x.num_actions} actions on ${x.num_cards} cards`}
                      >
                        <Checkbox 
                          label={x.label_id === 'none' ? 'none' : `${x.color} (${x.name})`} 
                          checked={this.trelloStore.selectedLabelIds.includes(x.label_id)}
                          onChange={(e) => { this.trelloStore.changeSelectedLabelIds(x.label_id); }}
                        />
                      </Tooltip>
                    </CheckBoxRow>
                  );
                })}
                </Collapse>
                </FilterDiv>
              </LabelsDiv>
              <ListsDiv>
              <FilterDiv label={
                <FilterTitle label={
                  <FilterTitleText
                    onClick={() => {this.trelloStore.setFilterListsOpened(!this.trelloStore.filterListsOpened)}}
                  >
                    <Icon icon={this.trelloStore.filterListsOpened ? "caret-down" : "caret-right"} />&nbsp;
                    <span style={{display:'inline-block'}}>
                      <Tooltip placement="left" content={<span>{i18n.t("filter-by-list-tooltip")}</span>}>
                        <div>{i18n.t("filter-by-list")}</div>
                      </Tooltip>
                    </span>
                  </FilterTitleText>                     
                } inline>
                  <Checkbox 
                    indeterminate={
                      (0 < this.trelloStore.selectedListIds.length) && 
                      (this.trelloStore.selectedListIds.length < this.trelloStore.selectableLists.length)                    
                    } 
                    checked={this.trelloStore.selectedListAll}
                    onChange={(e) => {this.trelloStore.changeSelectedListAll(e.currentTarget.checked)}}
                    style={{margin:'7px 0px'}}
                  />
                </FilterTitle>
              }>
                <Collapse isOpen={this.trelloStore.filterListsOpened}>
                {this.trelloStore.selectableLists.map(x => {
                  return (
                    <CheckBoxRow key={x.list_id}>
                      <Tooltip
                        placement="left"
                        content={`${x.num_actions} actions on ${x.num_cards} cards`}
                      >
                        <Checkbox 
                          label={x.list_id === 'none' ? 'none' : `${x.name}`} 
                          checked={this.trelloStore.selectedListIds.includes(x.list_id)}
                          onChange={(e) => { this.trelloStore.changeSelectedListIds(x.list_id); }}
                        />
                      </Tooltip>
                    </CheckBoxRow>
                  );
                })}
                </Collapse>
                </FilterDiv>
              </ListsDiv>
            </div>
          )}
      </Wrapper>
    )
  }
}

export default withTranslation()(OptionsMenu);