import { Component } from "react";

import { inject, observer } from "mobx-react";

import { RouteComponentProps, withRouter } from "react-router";

import styled from "styled-components/macro";

import {
  Card,
  Tab, Tabs,
} from "@blueprintjs/core";

import { AppStore } from "../../stores/AppStore";

import TicketInUserTab from "./tabs/TicketInUserTab";

const Wrapper = styled.div`
  min-height: calc(100vh - 50px);
`;
const Content = styled.div`
  max-width: 1000px;
  padding: 20px;
  margin: 0 auto;
`;

interface PageParams {
  tabId: string;
}
interface MyPageProps extends RouteComponentProps<PageParams> {}

interface InjectedProps extends MyPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class MyPage extends Component<MyPageProps> {
  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }

  render() {
    const { tabId } = this.props.match.params;

    return (
      <Wrapper>
        <Content>
          <h2>MyPage</h2>
          <div>
            <Card>
              <div>{this.userStore.username}</div>
            </Card>
          </div>

          <div style={{paddingTop:'20px'}}>
            <Tabs
              id="tabs-mypage"
              onChange={(newTabId) => {this.props.history.push(`/mypage/${newTabId}`)}}
              selectedTabId={tabId ? tabId : ''}
            >
              <Tab id="" title="Ticket" panel={(<TicketInUserTab />)} />
            </Tabs>         
          </div>
        </Content>
      </Wrapper>
    )
  }
}

export default withRouter(MyPage);