import { types } from "mobx-state-tree";

export const SelectableBoardModel = types.model("SelectableBoard", {
  board_id: types.optional(types.string, ''),
  board_name: types.optional(types.string, ''),
  board_url: types.optional(types.string, ''),
  workspace_name: types.optional(types.string, ''),
  background_image_url: types.optional(types.string, ''),
  last_activity_at: types.optional(types.string, ''),
  updated_at: types.optional(types.string, ''),
  expired_at: types.optional(types.string, ''),
});
type SelectableBoardType = typeof SelectableBoardModel.Type;
export interface SelectableBoard extends SelectableBoardType {}

export const BoardSettingsModel = types.model("BoardSettings", {
  send_weekly_report_emails: types.optional(types.array(types.string), []),
});
type BoardSettingsType = typeof BoardSettingsModel.Type;
export interface BoardSettings extends BoardSettingsType {}

export const MemberModel = types.model("Member", {
  member_id: types.optional(types.string, ''),
  fullname: types.optional(types.string, ''),
  username: types.optional(types.string, ''),
  chart_color: types.optional(types.string, ''),
  num_cards: types.optional(types.number, 0),
  num_actions: types.optional(types.number, 0),
  date_last_activity: types.optional(types.string, ''),
});
type MemberType = typeof MemberModel.Type;
export interface Member extends MemberType {}

export const LabelModel = types.model("Label", {
  label_id: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  color: types.optional(types.string, ''),
  num_cards: types.optional(types.number, 0),
  num_actions: types.optional(types.number, 0),
  date_last_activity: types.optional(types.string, ''),
});
type LabelType = typeof LabelModel.Type;
export interface Label extends LabelType {}

export const SelectableListModel = types.model("SelectableList", {
  list_id: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  num_cards: types.optional(types.number, 0),
  num_actions: types.optional(types.number, 0),
  date_last_activity: types.optional(types.string, ''),
});
type SelectableListType = typeof SelectableListModel.Type;
export interface SelectableList extends SelectableListType {}

export const ListModel = types.model("List", {
  list_id: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  pos: types.optional(types.number, 0),
  closed: types.optional(types.boolean, false),
});
type ListType = typeof ListModel.Type;
export interface List extends ListType {}

export const CardModel = types.model("Card", {
  card_id: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  desc: types.optional(types.string, ''),
  due: types.optional(types.string, ''),
  due_complete: types.optional(types.boolean, false),
  id_list: types.optional(types.string, ''),
  id_labels: types.optional(types.array(types.string), []),
  id_members: types.optional(types.array(types.string), []),
  id_checklists: types.optional(types.array(types.string), []),
  filter: types.optional(types.string, ''),
  date_last_activity: types.optional(types.string, ''),
  short_link: types.optional(types.string, ''),
  num_comments: types.optional(types.number, 0),
});
type CardType = typeof CardModel.Type;
export interface Card extends CardType {}

export const ActionModel = types.model("Action", {
  action_id: types.optional(types.string, ''),
  card_id: types.optional(types.string, ''),
  date: types.optional(types.string, ''),
  action_by: types.optional(types.string, ''),
  member: types.optional(types.string, ''),
  list: types.optional(types.string, ''),
  card: types.optional(types.string, ''),
  label: types.optional(types.string, ''),
  desc: types.optional(types.string, ''),
});
type ActionType = typeof ActionModel.Type;
export interface Action extends ActionType {}

export const CheckitemModel = types.model("Checkitem", {
  checkitem_id: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  state: types.optional(types.string, ''),
  pos: types.optional(types.number, 0),
  // due: types.optional(types.string, ''),
  // idMember: types.optional(types.string, ''),
});
type CheckitemType = typeof CheckitemModel.Type;
export interface Checkitem extends CheckitemType {}

export const ChecklistModel = types.model("Checklist", {
  checklist_id: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  pos: types.optional(types.number, 0),
  checkitems: types.optional(types.array(CheckitemModel), []),
});
type ChecklistType = typeof ChecklistModel.Type;
export interface Checklist extends ChecklistType {}

export const TicketModel = types.model("Ticket", {
  ticket_id: types.optional(types.string, ''),
  ticket_type: types.optional(types.string, 'free'), // 현재는 free 만 존재
  ticket_price: types.optional(types.number, 0.0),
  issue_by: types.optional(types.string, ''),
  issue_date: types.optional(types.string, ''),
  target_board_id: types.optional(types.string, ''),
  service_start_date: types.optional(types.string, ''),
  service_end_date: types.optional(types.string, ''),
});
type TicketType = typeof TicketModel.Type;
export interface Ticket extends TicketType {}
