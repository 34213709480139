import { Component } from "react";

import { observable, action, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import {  } from "@blueprintjs/core";
import { Line } from "react-chartjs-2";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;

interface ActionsTrendsTabProps {}

interface InjectedProps extends ActionsTrendsTabProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class ActionsTrendsTab extends Component<ActionsTrendsTabProps> {
  @observable yMax = 0;
  @action setYMax(value: number) { this.yMax = value; }

  private yAcc: number = 0;
  private ySuggest: number = 0;

  constructor(props: ActionsTrendsTabProps) {
    super(props);
    makeObservable(this);
  }

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }

  handleWheel = (e: any) => {
    this.yAcc += e.deltaY;
    if(this.yAcc >= 50) {
      this.setYMax(this.yMax+5);
      this.yAcc = 0;
    }
    if(this.yAcc <= -50) {
      if(this.yMax > this.ySuggest) {
        this.setYMax(this.yMax-5);
      }
      this.yAcc = 0;
    }
  }

  // 휠 이벤트가 다른 영역 스크롤을 일으키지 않도록 조정
  passivePreventDefault = (e: any) => {
    if (e.preventDefault) { e.preventDefault(); }
  }
  handleMouseEnter = (e: any) => {
    window.addEventListener('wheel', this.passivePreventDefault, {passive: false});
  }
  handleMouseLeave = (e: any) => {
    window.removeEventListener('wheel', this.passivePreventDefault);
  }

  render() {
    const data = JSON.parse(this.trelloStore.actionTrendsData);
    return (
      <Wrapper
        onWheel={this.handleWheel}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Line 
          data={{
            labels: data.labels ? data.labels : [],
            datasets: data.datasets ? data.datasets : []
          }}
          options={{
            scales: {
              xAxes: [{
                type: "time",
                time: {
                  unit: 'day',
                  unitStepSize: 7,
                  tooltipFormat: "YYYY-MM-DD",
                  displayFormats: {
                    day: 'YYYY-MM-DD'
                  }
                },
                gridLines: {
                  color: 'black',
                  borderDash: [20, 2],
                }
              }],
              yAxes: [{
                ticks: {
                  suggestedMax: this.yMax,
                  callback: (value: number, index: any, values: any) => {
                    if(this.yMax === 0) {
                      this.ySuggest = value;
                      this.setYMax(value);
                    }
                    return value;
                  },
                },
              }],
            },
            legend: { position: 'left', align: 'start' },
          }}
        />
      </Wrapper>
    )
  }
}

export default ActionsTrendsTab;