import { Component } from "react";

import { Redirect } from "react-router";
import { RouteComponentProps } from "react-router";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { } from "@blueprintjs/core";

import { AppStore } from "../../stores/AppStore";

const Wrapper = styled.div`
`;

interface LoginProcPageProps extends RouteComponentProps {}

interface InjectedProps extends LoginProcPageProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class LoginProcPage extends Component<LoginProcPageProps> {

  get injected() { return this.props as InjectedProps; }
  get userStore() { return this.injected.appStore.userStore; }
  get trelloStore() { return this.injected.appStore.trelloStore; }


  async componentDidMount() {
    // 토큰 요청 시 거부하는 등 에러 발생 처리
    let error_idx = this.props.location.hash.indexOf("error=");
    if(error_idx > -1) {
      alert(
        decodeURI(this.props.location.hash.substring(error_idx+"error=".length))
      );

      this.props.history.push("/");
      return;
    }

    // 올바로 토큰을 전달받으면 token 해시값만 존재한다
    let token = this.props.location.hash.substring("#token=".length);
    await this.userStore.login(token);
  }

  render() {
    if (this.userStore.isLoggedIn) {
      return <Redirect to="/" />;
    }

    return (
      <Wrapper>
      </Wrapper>
    );
  }
}

export default LoginProcPage;
