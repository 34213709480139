import { Component } from "react";

import { inject, observer } from "mobx-react";

import styled from "styled-components/macro";
import { 
  HTMLTable, Icon,
 } from "@blueprintjs/core";

import moment from "moment";

import { withTranslation } from "react-i18next";
import i18n from "../../../utils/i18n/i18n";

import { AppStore } from "../../../stores/AppStore";


const Wrapper = styled.div``;
const ChangedCard = styled.span`
  background: #a31542;
  color: white;
`;

interface BoardComparisonTabProps {}

interface InjectedProps extends BoardComparisonTabProps {
  appStore: AppStore;
}

@inject("appStore")
@observer
class BoardComparisonTab extends Component<BoardComparisonTabProps> {

  get injected() {
    return this.props as InjectedProps;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  get trelloStore() {
    return this.injected.appStore.trelloStore;
  }

  render_checkitem_status = (card: any, checklists: any) => {
    if(card.id_checklists && card.id_checklists.length > 0) {
      let num_checkitems_all = 0;
      let num_checkitems_completed = 0;
      card.id_checklists.map((id_checklist: string) => {
        const checklist = checklists.find((x: any) => x.checklist_id === id_checklist);
        if(checklist) {
          checklist.checkitems.map((checkitem: any) => {
            num_checkitems_all += 1;
            if(checkitem.state === 'complete') {
              num_checkitems_completed += 1
            }
            return null;
          })
        }
        return null;
      })
      if(num_checkitems_all > 0) {
        return `${num_checkitems_completed} / ${num_checkitems_all}`;
      }
    }     
    return '';
  }

  render_card = (card: any, card2: any = null) => {
    let card_name = (<span>{card.name}</span>);
    if (card2 && card.name !== card2.name) {
      card_name = (<ChangedCard>{card.name}</ChangedCard>);
    }

    let card_list_name = (<span></span>);
    let card_list = this.trelloStore.lists.find(x => x.list_id === card.id_list);
    if (card_list) {
      card_list_name = (<span>{card_list.name}</span>);
      if(card2) {
        let card2_list = this.trelloStore.lists.find(x => x.list_id === card2.id_list);
        if (card2_list && card2_list.name !== card_list.name) {
          card_list_name = (<ChangedCard>{card_list.name}</ChangedCard>);
        }
      }
    }

    let card_due = (<></>);
    if (card.due) {
      let card_date = moment(card.due).format("YYYY-MM-DD");
      card_due = (
        <div>
          <Icon icon="calendar" iconSize={12} />&nbsp;
          <span>{card_date}</span>
        </div>        
      );
      if(card2 && card2.due) {
        let card2_date = moment(card2.due).format("YYYY-MM-DD");
        if(card_date !== card2_date) {
          card_due = (
            <div>
              <Icon icon="calendar" iconSize={12} />&nbsp;
              <ChangedCard>{card_date}</ChangedCard>
            </div>    
          );
        }
      }
    }

    let card_checkitems = (<div></div>);
    if(card2) {
      // target date 컬럼
      let start_date_status = this.render_checkitem_status(card2, this.trelloStore.boardStartDateChecklists);
      let target_date_status = this.render_checkitem_status(card, this.trelloStore.checklists);
      if(start_date_status === target_date_status) {
        card_checkitems = (<div>{start_date_status}</div>);
      } else {
        card_checkitems = (<ChangedCard>{target_date_status}</ChangedCard>);
      }
      
    } else {
      // start date 컬럼
      let start_date_status = this.render_checkitem_status(card, this.trelloStore.boardStartDateChecklists);
      if(start_date_status !== '') {
        card_checkitems = (<div>{start_date_status}</div>);
      }
    }

    let card_comments = (<div></div>);
    if (card.num_comments) {
      card_comments = (
        <div>
          <Icon icon="comment" iconSize={12} />&nbsp;
          <span>{card.num_comments}</span>
        </div>        
      );
      if(card2 && card2.num_comments) {
        if(card.num_comments !== card2.num_comments) {
          card_comments = (
            <div>
              <Icon icon="comment" iconSize={12} />&nbsp;
              <ChangedCard>{card.num_comments}</ChangedCard>
            </div>    
          );
        }
      }
    }
    // let card_members = card.id_members.map((card_member_id: string) => {
    //   const model_member = this.trelloStore.selectableMembers.find(x => x.member_id === card_member_id)
    //   return model_member ? model_member.fullname : '';
    // })
    // let card_labels = card.id_labels.map((card_label_id: string) => {
    //   const model_label = this.trelloStore.selectableLabels.find(x => x.label_id === card_label_id)
    //   return model_label ? model_label.name : '';
    // });

    return (
      <div>
        <div>{card_name}</div>
        <div>{card_list_name}</div>
        {card_due}
        {/* 
        {card_members && card_members.length > 0 && (
          <div>
            <Icon icon="user" iconSize={12} />&nbsp;
            <span>{card_members.join(', ')}</span>
          </div>
        )}  
        {card_labels && card_labels.length > 0 && (
            <div>
              <Icon icon="label" iconSize={12} />&nbsp;
              <span>{card_labels.join(', ')}</span>
            </div>
        )} 
        */}
        {card_comments}
        {card_checkitems}
      </div>
    )
  }

  render() {
    return (
      <Wrapper>
        <HTMLTable bordered striped style={{width:'100%'}}>
          <thead>
            <tr>
              <th>#</th>
              <th>{i18n.t("filter-startdate")}</th>
              <th>{i18n.t("filter-targetdate")}</th>
            </tr>
          </thead>
          <tbody>
            {this.trelloStore.cards.map((t_card, i) => {

              const s_card = this.trelloStore.boardStartDateCards.find(x => x.card_id === t_card.card_id);

              return (
                <tr key={t_card.card_id}>
                  <td>{i+1}</td>
                  <td>{s_card && this.render_card(s_card)}</td>
                  <td>{this.render_card(t_card, s_card)}</td>
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
      </Wrapper>
    )
  }
}

export default withTranslation()(BoardComparisonTab);